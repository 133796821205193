<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2025-01-06 09:42:12
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-06 14:23:51
 * @FilePath: /erp-admin/src/componentBase/AlbbPublishProduct/components/checkboxAndRadio.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="w">
    <el-checkbox
      v-model="checked1"
      :label="title"
      @change="handleCheckedChange"
    />
    <div v-show="checked1" class="bg ptb5 plr10">
      <el-radio-group v-model="appModel" @change="handleRadioChange">
        <el-radio
          v-for="(rad, i) in config.dataSource"
          :key="i"
          :disabled="rad.disabled"
          :label="rad.value"
        >
          {{ rad.text }}
          <span v-if="rad.link" v-html="rad.link"></span>
          <el-popover
            v-if="rad.help"
            placement="right"
            trigger="hover"
            width="300"
          >
            <div class="popover-content-wrapper" v-html="rad.help"></div>
            <template #reference>
              <jwt-icon class="gd fs16" icon="question-fill" />
            </template>
          </el-popover>
        </el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script setup>
  import {
    computed,
    defineProps,
    ref,
    watchEffect,
    defineEmits,
    nextTick,
  } from 'vue'
  const props = defineProps({
    modelValue: { type: [String, Number], default: null },
    config: { type: Object, default: () => {} },
    title: { type: String, default: '分阶段付款' },
  })
  const emit = defineEmits(['update:modelValue'])
  const checked1 = ref(false)
  const appModel = computed({
    get: () => {
      return props.modelValue
    },
    set: (value) => {
      emit('update:modelValue', value)
    },
  })

  function handleCheckedChange(val) {
    if (val) {
      checked1.value = true
      appModel.value = props.config?.dataSource[0]?.value
    } else {
      checked1.value = false
      appModel.value = ''
    }
    handleEmitData()
  }
  function handleEmitData() {
    nextTick(() => {
      emit('update:modelValue', appModel.value)
    })
  }
  function handleRadioChange() {
    handleEmitData()
  }

  watchEffect(() => {
    checked1.value = !!props.modelValue
  })
</script>

<style lang="scss" scoped></style>
