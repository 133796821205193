<template>
  <div
    class="logo-container"
    :class="{
      ['logo-container-' + theme.layout]: true,
    }"
  >
    <router-link
      style="display: flex; align-items: center; justify-content: center"
      to="/"
    >
      <div class="logo">
        <!-- 使用自定义svg示例 -->
        <!-- <vab-icon v-if="logo" :icon="require('@/assets/admin-logo.png')" /> -->
        <div
          style="
            width: 35px;
            height: 35px;
            margin: 0 auto;
            padding: 2px;
            border-radius: 5px;
            background: linear-gradient(to right, #c9d6df, #f0f5f9);
            display: flex;
            align-items: center;
          "
        >
          <img :src="fillUrl(logo)" style="width: 100%" />
        </div>
      </div>
      <div
        v-if="!collapse"
        class="title fxmc fv"
        :class="{ 'hidden-xs-only': theme.layout === 'horizontal' }"
        style="display: flex"
      >
        <div class="db b">
          {{ title }}
          <span>{{ COMPANY_VIP_VERSION[vipVersion] }}</span>
        </div>
        <span class="fs12 lh1 dl fx" @click.stop="">
          <el-popover placement="bottom" trigger="hover" :width="150">
            <div class="mt2">
              <div v-if="expireDay !== '-'">
                <template v-if="+expireDay < 0">
                  <span>{{ t('授权已过期') }}</span>
                </template>
                <template v-else>
                  <span>{{ t('服务将于') }}</span>
                  <span class="co b">{{ ' ' }}{{ expireDay }}{{ ' ' }}</span>
                  <span>{{ t('天后到期') }}</span>
                </template>
              </div>
              <div v-else>{{ t('服务永不到期') }}</div>
            </div>
            <!-- <template #reference>
              <span>{{ COMPANY_VIP_VERSION[vipVersion] }}</span>
            </template> -->
          </el-popover>
          <span v-if="expireDay !== '-'">
            <span v-if="+expireDay < 30 || !vipVersion" class="mt2 fx">
              <template v-if="+expireDay < 0">
                <span class="cd b">{{ t('授权已过期') }}</span>
              </template>
              <template v-else>
                <span>{{ t('服务将于') }}</span>
                <span class="co b">{{ ' ' }}{{ expireDay }}{{ ' ' }}</span>
                <span>{{ t('天后到期') }}</span>
              </template>
            </span>
            <!-- <span v-else>
              <span>{{ t('到期日期') }}：{{}}</span>
            </span> -->
          </span>
          <span v-else>{{ t('服务永不到期') }}</span>
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { COMPANY_VIP_VERSION } from '@/config/variable.config'
  import { fillUrl } from '@/common/js/utils'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'VabLogo',
    setup() {
      const store = useStore()

      return {
        logo: computed(
          () =>
            store.getters['settings/logo'] +
            '?imageMogr2/gravity/center/crop/65x55/interlace/0'
        ),
        title: computed(() => store.getters['settings/title']),
        collapse: computed(() => store.getters['settings/collapse']),
        theme: computed(() => store.getters['settings/theme']),
        COMPANY_VIP_VERSION,
        vipVersion: computed(() => store.getters['sys/vipVersion']),
        expireDay: computed(() => store.getters['sys/expireDay']),
        expire_time: computed(() => store.getters['sys/websiteConfing']),
        fillUrl,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  @mixin container {
    position: relative;
    // height: $base-header-height;
    overflow: hidden;
    // line-height: $base-header-height;
    background: transparent;
    height: 50px;
  }

  @mixin logo {
    display: inline-block;
    color: $base-title-color;
    vertical-align: middle;
    align-items: center;
    text-align: center;
  }

  @mixin title {
    display: inline-block;
    margin-left: 10px;
    overflow: hidden;
    font-size: 18px;
    // line-height: 55px;
    line-height: 1;
    color: $base-title-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }

  .logo-container {
    &-horizontal,
    &-common {
      @include container;

      .logo {
        svg,
        img {
          @include logo;
        }
      }

      // .title {
      //   @include title;
      // }
    }

    &-vertical,
    &-column,
    &-comprehensive,
    &-float {
      @include container;
      padding-top: 7px;
      // height: $base-logo-height;
      // line-height: $base-logo-height;
      text-align: center;

      .logo {
        svg,
        img {
          @include logo;
        }
      }

      .title {
        @include title;
        max-width: $base-left-menu-width - 60;
      }
    }

    &-column {
      background: var(--vab-column-second-menu-background) !important;

      .logo {
        position: fixed;
        top: 0;
        display: block;
        width: $base-left-menu-width-min;
        height: $base-logo-height;
        margin: 0;
        // background: var(--vab-column-first-menu-background);
        // 临时样式
        background: white;
        border-right: 1px solid #c9d6df;
        width: 64.5px;
        padding-top: 10px;
      }
    }
    .title {
      .db {
        display: flex;
        align-items: center;
        justify-content: center;
        // border-bottom: 2px solid #52616b;
        // padding-bottom: 5px;
        span {
          font-size: 12px;
          font-weight: normal;
          background: #ff731d;
          padding: 2px 5px;
          border-radius: 3px;
          color: #fff7e9;
          margin-left: 5px;
        }
      }
      // text-align: left;
      // padding-right: 15px;
      // padding-left: 15px;
      // padding-top: 10px;
      // margin-left: 3px !important;
      // color: #52616b !important;
      // background: var(--vab-column-second-menu-background) !important;
      // @include title;
    }
  }
</style>
