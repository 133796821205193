<!--
 * @Author: WGL
 * @Date: 2023-12-13 15:34:38
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-30 15:25:41
 * @Description: 
-->
<template>
  <el-form-item
    v-show="!isSpecsSku"
    :label="item.label"
    v-bind="item.formAttrs"
  >
    <div v-if="item?.promote?.top" class="g9" v-html="item.promote.top"></div>
    <el-table border :data="appModel[item.labelKey]" stripe>
      <el-table-column
        v-for="(column, index) in tableColumns"
        :key="index"
        align="center"
        :min-width="50"
        v-bind="column"
      >
        <template #default="{ row, $index }">
          <!-- 操作 -->
          <template v-if="column.prop === 'operation'">
            <el-button link @click="handleAddPriceRange(item)">
              <span class="fs20">+</span>
            </el-button>
            <el-button
              class="fs24"
              :disabled="!$index"
              link
              @click="handleRemovePriceRange(item, $index)"
            >
              <span class="fs24">-</span>
            </el-button>
          </template>
          <!-- 购买数量 -->
          <template v-else-if="column.prop === 'pricerange_beginAmount'">
            <el-input-number
              v-model="row.pricerange_beginAmount"
              :controls="false"
              controls-position="both"
              :max="999999"
              :min="0"
              placeholder="请输入"
              :step="1"
            />
          </template>
          <!-- 单价 -->
          <template v-else-if="column.prop === 'pricerange_price'">
            <el-input-number
              v-model="row.pricerange_price"
              :controls="false"
              controls-position="both"
              :max="999999"
              :min="0"
              placeholder="请输入"
              :step="1"
            />
          </template>
          <!-- 预览 -->
          <template v-else-if="column.prop === 'view'">
            <div v-if="row.pricerange_beginAmount && row.pricerange_price">
              >={{ row.pricerange_beginAmount }}{{ productUnit }}：{{
                row.pricerange_price
              }}元/{{ productUnit }}
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </el-form-item>
</template>

<script>
  // 产品按数量报价
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'ProductPriceRange',
    props: {
      item: { type: Object, default: () => {} },
      formModel: { type: Object, default: () => {} },
      isSpecsSku: { type: Boolean, default: false }, // 是否是按规格sku报价
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const appModel = computed({
        get: () => {
          return props.formModel || {}
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      const productUnit = computed(() => {
        return props.formModel.cbuUnit
      })

      const tableColumns = [
        { label: '操作', width: 90, prop: 'operation', align: 'center' },
        {
          label: '购买数量',
          prop: 'pricerange_beginAmount',
          width: 200,
          align: 'center',
        },
        {
          label: '单价',
          width: 200,
          prop: 'pricerange_price',
          align: 'center',
        },
        {
          label: '预览（可根据买家采购的不同数量设置不同价格）',
          width: '',
          prop: 'view',
          align: 'left',
        },
      ]

      /**
       * @description 新增价格区间
       * @param {*} item
       */
      const handleAddPriceRange = (item) => {
        appModel.value[item.labelKey].push({
          pricerange_beginAmount: null,
          pricerange_price: null,
        })
      }

      /**
       * @description 删除价格区间
       * @param {*} item
       * @param {*} index
       */
      const handleRemovePriceRange = (item, index) => {
        appModel.value[item.labelKey].splice(index, 1)
      }

      return {
        appModel,
        tableColumns,
        productUnit,
        handleAddPriceRange,
        handleRemovePriceRange,
      }
    },
  })
</script>

<style scoped></style>
