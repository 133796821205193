import { getList } from '@/api/app'
import { getPlaceList } from '@/api/place'
import { getUserApps, getUserFieldData } from '@/api/user'
import { fillUrl } from 'common/js/utils'
import { getWebsiteConfing, getDictConfing, getSysConfigSetting } from '@/api/sysSet'
import { productCategoryList } from '@/api/product'
import { customerCategoryList } from '@/api/customer'
import { getCustomsCountry } from '@/api/customs'
import { supplierCategoryList } from '@/api/supplier'
import { storeCategoryList } from '@/api/storeHouse'
import { handleStoreCategory } from '@/utils/store'
import { targetCategoryList } from '@/api/target'
import { handleTargetCategory } from '@/utils/target'
import { handleProductCategoryList } from '@/utils/index'
import { windowReload } from '@/utils/business'
import { getTypeNotice } from '@/api/notice'
import { getRepositoryTree } from '@/api/repository'
import { getAlbbProductCategoryTree } from '@/api/third'
import { getSupportExpress } from '@/api/expressDelivery'
import { getUserMenuBadge, clearUserMenuBadge } from '@/api/menuManagement'
import { getAreaList } from '@/api/area'
import dayjs from 'dayjs'
import { version } from '@/config'


const state = () => ({
  userAppList: [], // 用户拥有的产品
  placeList: [], // 地区
  areaTree: [], // 国家地区
  indexBoardStatistics: {}, // 首页数据统计
  websiteConfing: {}, // 网站信息配置
  productCategory: [], // 产品分类
  customerCategory: [], // 用户分类
  supplierCategory: [], //供应商分类
  storeCategory: [], //仓库分类
  targetCategory: [], //目标分组
  noticeTypeTree: [], // 消息类型
  localDict: {}, // 本地字典
  repositoryList: [], // 库房列表
  hasOpenDrawer: false, // 是否已经打开弹窗
  openPageDrawerConfig: {}, // 打开弹窗的详情配置
  openPageDrawerConfigList: [], // 打开弹窗的详情配置
  asynExportFileList: [], // 异步导出文件队列
  userFieldList: {},  // 用户列表字段权限
  refreshTable: false, // 刷新列表数据
  refreshProductBaseList: false, // 刷新产品基础信息列表
  refreshDrawerDetail: 0, // 刷新详情数据
  showAddProductDrawer: false, // 显示添加左侧产品弹窗
  addProductDrawerParams: {}, // 显示添加左侧产品弹窗 参数信息
  addProductDrawerAddSuccess: 0, // 显示添加左侧产品弹窗 参数信息
  addProductDrawerSelect: [], // 显示添加左侧选中产品
  detailDrawerSize: '', // 详情弹窗size
  productQuoteHistory: {}, // 产品的历史报价
  productCustomerQuoteHistory: {}, // 产品客户的历史报价
  pageLeftDrawerInfo: {}, // 左侧弹窗
  deleteProductSelectInfo: {},
  customsCountryList: [], // 海关国家
  sysConfigSetting: {}, // 系统配置
  albbCategoryTree: [], // 阿里巴巴产品分类
  supportExpress: {}, // 支持的快递
  supportExpressKb: {}, // 支持的快递 (快宝)
  productBaseListFullscreen: false, // 
  menuBadge: {} // 菜单角标
})
const getters = {
  userAppList: (state) => state.userAppList,
  placeList: (state) => state.placeList,
  areaTree: (state) => state.areaTree,
  indexBoardStatistics: (state) => state.indexBoardStatistics,
  deviceStatus: (state, getters) => {
    const data = [
      { value: getters.indexBoardStatistics.device.online, name: '运行中' },
      { value: getters.indexBoardStatistics.device.offline, name: '已下线' },
      // { value: 0, name: '故障' },
    ]
    return data
  },
  websiteConfing: (state) => state.websiteConfing,
  // 是否是汽配
  isAutoParts: (state) => {
    let result = !!LS.get('isAutoParts') || state.websiteConfing?.industry_info?.type === 1
    return result
  },
  // 是否是家具
  isFurniture: (state) => {
    let result = +state?.sysConfigSetting?.furniture_mode
    return !!result
  },
  vipVersion: (state) => state.websiteConfing.vip_version, // 当前版本
  companyId: (state) => state.websiteConfing.id, // 公司
  expireDay: (state) => {
    const result = state.websiteConfing.expire_time
      ? dayjs(state.websiteConfing.expire_time).diff(dayjs(), 'd')
      : '-'
    return result
  }, // 到期时间
  productCategory: (state) => state.productCategory,
  storeCategory: (state) => {
    handleStoreCategory(state.storeCategory)
    return state.storeCategory
  },
  customerCategory: (state) => state.customerCategory,
  supplierCategory: (state) => state.supplierCategory,
  targetCategory: (state) => {
    handleTargetCategory(state.targetCategory)
    return state.targetCategory
  },
  noticeTypeTree: (state) => state.noticeTypeTree,
  noticeSubType: (state) => {
    const obj = {}
    state.noticeTypeTree?.map((item) => {
      if (item.msg_sub_type) {
        item.msg_sub_type.map((sub) => {
          const { id, title, content, type } = sub
          obj[id] = {
            id,
            title,
            content,
            type,
          }
        })
      }
    })
    return obj
  },
  localDict: (state) => state.localDict,
  repositoryTree: (state) => state.repositoryList,
  hasOpenDrawer: (state) => state.hasOpenDrawer,
  openPageDrawerConfig: (state) => state.openPageDrawerConfig,
  openPageDrawerConfigList: (state) => state.openPageDrawerConfigList,
  asynExportFileList: (state) => {
    let result = state.asynExportFileList
    if (!result?.length) {
      result = LS.get('asynExportFile') || []
    }
    return result
  },
  userFieldList: (state) => state.userFieldList,
  refreshTable: (state) => state.refreshTable,
  refreshProductBaseList: (state) => state.refreshProductBaseList,
  showAddProductDrawer: (state) => state.showAddProductDrawer,
  addProductDrawerParams: (state) => state.addProductDrawerParams,
  addProductDrawerSelect: (state) => state.addProductDrawerSelect,
  addProductDrawerAddSuccess: (state) => state.addProductDrawerAddSuccess,
  deleteProductSelectInfo: (state) => state.deleteProductSelectInfo,
  productQuoteHistory: (state) => state.productQuoteHistory,
  productCustomerQuoteHistory: (state) => state.productCustomerQuoteHistory,
  pageLeftDrawerInfo: (state) => state.pageLeftDrawerInfo,
  refreshDrawerDetail: (state) => state.refreshDrawerDetail,
  customsCountryList: (state) => state.customsCountryList,
  detailDrawerSize: (state) => state.detailDrawerSize,
  sysConfigSetting: (state) => state.sysConfigSetting,
  saleQuoteMode: (state) => +state.sysConfigSetting.sale_quote_mode, // 销售报价模式 0 宽松 1 严格
  purchaseQuoteMode: (state) => +state.sysConfigSetting.purchase_quote_mode, // 采购报价模式 0 宽松 1 严格
  albbCategoryTree: (state) => state.albbCategoryTree,
  supportExpress: (state) => state.supportExpress,
  supportExpressKb: (state) => state.supportExpressKb,
  productBaseListFullscreen: (state) => state.productBaseListFullscreen,
  menuBadge: (state) => state.menuBadge,
  menuBadgePid: (state, getters, rootState, rootGetters) => {
    const obj = {}
    for (const key of Object.keys(state.menuBadge)) {
      if (rootGetters['routes/menusIdObj'][key]) {
        const pid = rootGetters['routes/menusIdObj'][key].pid
        if (pid) {
          obj[pid] = 1
        }
      }
    }
    return obj
  },
}

const mutations = {
  setUserApp(state, userAppList) {
    state.userAppList = userAppList
  },
  setPlace(state, placeList) {
    state.placeList = placeList
    SS.set('placeList', placeList)
  },
  setAreaTree(state, areaTree) {
    state.areaTree = areaTree
    SS.set('areaTree', areaTree)
  },
  setIndexBoardStatistics(state, indexBoardStatistics) {
    console.log('setIndexBoardStatistics', indexBoardStatistics)
    state.indexBoardStatistics = indexBoardStatistics
  },
  setWebsiteData(state, websiteConfing) {
    console.log('setWebsiteData', websiteConfing)
    state.websiteConfing = websiteConfing
  },
  setCompanyList(state, companyList) {
    state.companyList = companyList
  },
  setProductCategory(state, productCategory) {
    state.productCategory = productCategory
  },
  setCustomerCategory(state, customerCategory) {
    state.customerCategory = customerCategory
  },
  setSupplierCategory(state, supplierCategory) {
    state.supplierCategory = supplierCategory
  },
  setStoreCategory(state, storeCategory) {
    state.storeCategory = storeCategory
  },
  setTargetCategory(state, targetCategory) {
    state.targetCategory = targetCategory
  },
  setNoticeTypeTree(state, noticeTypeTree) {
    state.noticeTypeTree = noticeTypeTree
    SS.set('noticeTypeTree', noticeTypeTree)
  },
  setLocalDict(state, localDict) {
    state.localDict = localDict
    SS.set('localDict', localDict)
  },
  setRepositoryList(state, repositoryList) {
    state.repositoryList = repositoryList
  },
  setOpenDrawer(state, hasOpenDrawer) {
    state.hasOpenDrawer = hasOpenDrawer
  },
  setOpenPageDrawerConfig(state, openPageDrawerConfig) {
    state.openPageDrawerConfigList.push(openPageDrawerConfig)
    state.openPageDrawerConfig = openPageDrawerConfig
  },
  deleteLastOpenPageDrawerConfig(state) {
    state.openPageDrawerConfigList.pop()
  },
  clearOpenPageDrawerConfig(state) {
    state.openPageDrawerConfigList = []
  },
  setAsynExportFileList(state, asynExportFileList) {
    state.asynExportFileList = asynExportFileList
    LS.set('asynExportFile', asynExportFileList)
  },
  setUserFieldList(state, userFieldList) {
    state.userFieldList = userFieldList
  },
  setRefreshTable(state, refreshTable) {
    state.refreshTable = refreshTable
  },
  setRefreshProductBaseList(state, refreshProductBaseList) {
    state.refreshProductBaseList = refreshProductBaseList
  },
  setShowAddProductDrawer(state, showAddProductDrawer) {
    state.showAddProductDrawer = showAddProductDrawer
    if (!showAddProductDrawer) {
      state.addProductDrawerParams = null
    }
  },
  setAddProductDrawerParams(state, addProductDrawerParams = null) {
    state.addProductDrawerParams = addProductDrawerParams
    console.log('addProductDrawerParams :>> ', addProductDrawerParams);
  },
  setAddProductDrawerSelect(state, addProductDrawerSelect) {
    const oldSelect = state.addProductDrawerSelect
    const newSelect = addProductDrawerSelect.map(item => {
      const oldItem = oldSelect.find(oldItem => oldItem.id === item.id)
      if (oldItem) {
        // 保留已修改的数据信息
        return oldItem
      } else {
        return item
      }
    })
    state.addProductDrawerSelect = newSelect
  },
  setAddProductDrawerSelectIndex(state, id) {
    state.addProductDrawerSelect = state.addProductDrawerSelect.filter((item) => {
      return item.id !== id
    })
  },
  setAddProductDrawerAddSuccess(state, addProductDrawerAddSuccess) {
    state.addProductDrawerAddSuccess = addProductDrawerAddSuccess
  },
  setDeleteProductSelectInfo(state, deleteProductSelectInfo) {
    state.deleteProductSelectInfo = deleteProductSelectInfo
  },
  setProductQuoteHistory(state, productQuoteHistory) {
    state.productQuoteHistory = productQuoteHistory
  },
  setProductCustomerQuoteHistory(state, productCustomerQuoteHistory) {
    console.log('productCustomerQuoteHistory :>> ', productCustomerQuoteHistory);
    state.productCustomerQuoteHistory = productCustomerQuoteHistory
  },
  setPageLeftDrawerInfo(state, pageLeftDrawerInfo) {
    state.pageLeftDrawerInfo = pageLeftDrawerInfo
  },
  setRefreshDrawerDetail(state, refreshDrawerDetail) {
    state.refreshDrawerDetail = refreshDrawerDetail
  },
  setCustomsCountryList(state, customsCountryList) {
    state.customsCountryList = customsCountryList
  },
  setDetailDrawerSize(state, detailDrawerSize) {
    state.detailDrawerSize = detailDrawerSize
  },
  setSysConfigSetting(state, sysConfigSetting) {
    state.sysConfigSetting = sysConfigSetting
  },
  setAlbbCategoryTree(state, albbCategoryTree) {
    state.albbCategoryTree = albbCategoryTree
  },
  setSupportExpress(state, supportExpress) {
    state.supportExpress = supportExpress
  },
  supportExpressKb(state, supportExpressKb) {
    state.supportExpressKb = supportExpressKb
  },
  setProductBaseListFullscreen(state, productBaseListFullscreen) {
    console.log('productBaseListFullscreen :>> ', productBaseListFullscreen);
    state.productBaseListFullscreen = productBaseListFullscreen
  },
  setMenuBadge(state, menuBadge) {
    state.menuBadge = menuBadge
  }
}
const actions = {
  async getAppList({ commit }) {
    const result = await getList()
    const list = result.data.map((item) => {
      item.label = item.title
      item.value = item.id
      item.logo = fillUrl(item.logo)
      return item
    })
    commit('setApp', list)
  },

  async getUserAppList({ commit }) {
    const result = await getUserApps()
    const list = result.data.map((item) => {
      item.label = item.title
      item.value = item.id
      item.logo = fillUrl(item.logo)
      return item
    })
    commit('setUserApp', list)
  },

  async getPlaceList({ commit }) {
    const result = await getPlaceList()
    commit('setPlace', result.data || [])
  },
  async getAreaTreeData({ commit }) {
    const local = SS.get('areaTree')
    let result = null
    if (local.length) {
      result = local
    } else {
      result = await getAreaList()
    }
    commit('setAreaTree', result || [])
  },
  async getWebsiteData({ commit }) {
    const result = (await getWebsiteConfing()) || {}
    if (Object.keys(result).length) {
      for (const [key, val] of Object.entries(result)) {
        if (val && F.isString(val) && val.includes('/uploads/')) {
          result[key] = fillUrl(val)
        }
      }
    }
    LS.set('isAutoParts', result?.industry_info?.type === 1)
    LS.set('companyId', result.id)
    commit('setWebsiteData', result || {})
  },

  // 获取产品分类
  async getProductCategoryList({ commit }) {
    const result = (await productCategoryList()) || {}
    handleProductCategoryList(result.data)
    commit('setProductCategory', result.data || [])
  },
  // 获取仓库分类
  async getStoreCategoryList({ commit }) {
    const result = await storeCategoryList()
    handleProductCategoryList(result.data)
    commit('setStoreCategory', result.data || [])
    console.log('result :>> ', result)
  },
  // 获取用户分类
  async getCustomerCategoryList({ commit }) {
    const result = await customerCategoryList()
    commit('setCustomerCategory', result.data || [])
  },
  // 获取供应商分类
  async getSupplierCategoryList({ commit }) {
    const result = await supplierCategoryList()
    commit('setSupplierCategory', result.data || [])
  },
  // 获取目标分组
  async getTargetCategoryList({ commit }) {
    const result = await targetCategoryList()
    commit('setTargetCategory', result.data || [])
  },
  // 获取通知消息类型
  async getTypeNoticeList({ commit }) {
    const local = SS.get('noticeTypeTree')
    let result = null
    if (local.length) {
      result = local
    } else {
      result = (await getTypeNotice()) || []
      result.map((item) => {
        item.children = item.msg_sub_type
      })
    }
    commit('setNoticeTypeTree', result || [])
  },

  // 获取通用配置文件
  async getLocalDict({ commit }) {
    const local = SS.get('localDict')
    const localVersion = LS.get('localVersion')
    let result = {}
    if (Object.keys(local).length && version === localVersion) {
      result = local
    } else {
      result = await getDictConfing()
      LS.set('localVersion', version)
      setTimeout(() => {
        windowReload()
      }, 3000)
    }
    commit('setLocalDict', result || [])
  },
  // 获取所有仓库数据
  async getAllRepositoryList({ commit }) {
    const result = await getRepositoryTree({})
    commit('setRepositoryList', result || [])
  },
  // 删除正在导出文件
  deleteAsynExportFileList({ commit }, id) {
    const asynExportFileList = LS.get('asynExportFile')
    asynExportFileList.map((item, index) => {
      if (item.id === id) {
        asynExportFileList.splice(index, 1)
      }
    })
    console.log('deleteAsynExportFileList :>> ', asynExportFileList);
    commit('setAsynExportFileList', asynExportFileList)
  },
  // 获取用户列表页面字段权限
  async getUserFieldList({ commit }) {
    const result = await getUserFieldData({})
    const resultObj = {}
    for (const [key, val] of Object.entries(result)) {
      let obj = null
      if (val.length) {
        obj = {}
        val.map(item => {
          obj[item.prop] = item
        })
      }
      resultObj[key] = obj
    }
    commit('setUserFieldList', resultObj || {})
  },

  // 关闭弹窗
  handleCloseDrawer({ commit, dispatch }) {
    commit('setOpenDrawer', false)
    commit('clearOpenPageDrawerConfig')
    dispatch('handleCloseAddProductDrawer')
  },

  // 弹窗返回上一级
  handleBackDrawer({ commit, dispatch }) {
    commit('deleteLastOpenPageDrawerConfig')
    dispatch('handleCloseAddProductDrawer')
  },

  // 关左侧产品添加弹窗
  handleCloseAddProductDrawer({ commit }) {
    // 关闭弹窗
    commit('setShowAddProductDrawer', false)
    commit('setAddProductDrawerSelect', [])
  },

  // 获取海关国家数据
  async getCustomsCountryList({ commit }) {
    const local = SS.get('customsCountryList')
    let result = null
    if (local.length) {
      result = local
    } else {
      result = (await getCustomsCountry()) || []
      SS.set('customsCountryList', result)
    }
    commit('setCustomsCountryList', result || [])
  },

  async handleGetSysConfigSetting({ commit }) {
    const result = await getSysConfigSetting()
    commit('setSysConfigSetting', result || {})
  },
  async handleGetAlbbProductCategoryTree({ commit }) {
    const result = await getAlbbProductCategoryTree()
    commit('setAlbbCategoryTree', result || [])
  },

  async handleGetSupportExpress({ commit }) {
    const local = SS.get('supportExpress')
    let result = null
    if (local && Object.keys(local).length) {
      result = local
    } else {
      result = await getSupportExpress({ type: 'kuaidi100' })
      SS.set('supportExpress', result)
    }
    commit('setSupportExpress', result || {})
  },
  async handleGetSupportExpressKb({ commit }) {
    const local = SS.get('supportExpressKb')
    let result = null
    if (local && Object.keys(local).length) {
      result = local
    } else {
      result = await getSupportExpress({ type: 'kuaibao' })
      SS.set('supportExpressKb', result)
    }
    commit('supportExpressKb', result || {})
  },
  // 获取菜单的badge
  async handleGetMenuBadge({ commit }) {
    const result = await getUserMenuBadge()
    console.log('handleGetMenuBadge', result)
    commit('setMenuBadge', result || {})
  },
  // 循环请求获取菜单徽章
  async handleGetMenuBadgeLoop({ dispatch }) {
    dispatch('handleGetMenuBadge').then(() => {
      setTimeout(() => {
        dispatch('handleGetMenuBadgeLoop')
      }, 1000 * 5 * 60);
    })

  },
  /**
   * 清除用户菜单徽章并重新获取菜单徽章列表
   *
   * @param param0 参数对象，包含 dispatch 函数
   * @param id 用户ID
   * @returns 无返回值，但会触发获取菜单徽章列表的动作
   */
  async handleClearUserMenuBadge({ dispatch }, id) {
    await clearUserMenuBadge(id)
    dispatch('handleGetMenuBadge')
  },
  // 系统轮询请求
  async handleSysPolling({ dispatch }) {
    // 轮询
    dispatch('handleGetMenuBadgeLoop')
  }
}
export default { state, getters, mutations, actions }
