<!--
 * @Author: WGL
 * @Date: 2023-12-12 10:41:37
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-14 17:38:50
 * @Description: 
-->
<template>
  <div v-if="item?.promote?.top" class="g9" v-html="item.promote.top"></div>
  <slot :name="item.labelKey">
    <el-input
      v-if="item.uiType === 'input'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      type="text"
    />
    <el-select
      v-else-if="item.uiType === 'select'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      class="w"
      value-key="value"
      @change="hanldeItemChange($event, item)"
    >
      <el-option
        v-for="(option, i) in item.dataSource"
        :key="i"
        :label="option.text"
        :value="option"
      />
    </el-select>
    <FreightSelect
      v-else-if="item.uiType === 'freightSelect'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      :config="item"
      :data-source="appModel"
      :shopid="shopid"
      @change="hanldeItemChange($event, item)"
    />
    <el-radio-group
      v-else-if="item.uiType === 'radio'"
      v-model="appModel[item.labelKey]"
      @change="handleRadioChange"
    >
      <el-radio
        v-for="(rad, i) in item.dataSource"
        :key="i"
        :disabled="rad.disabled"
        :label="rad.value"
      >
        {{ rad.text }}
        <span v-if="rad.link" v-html="rad.link"></span>
        <el-popover v-if="rad.help" placement="bottom" trigger="hover" width="">
          <div class="popover-content-wrapper" v-html="rad.help"></div>
          <template #reference>
            <jwt-icon class="gd fs16" icon="question-fill" />
          </template>
        </el-popover>

        <!-- <el-tooltip
          v-if="rad.help"
          :content="rad.help"
          effect="light"
          placement="right"
        /> -->
      </el-radio>
    </el-radio-group>
    <!-- 特殊处理定时开售 -->
    <el-radio-group
      v-else-if="item.uiType === 'shelftime'"
      v-model="appModel[item.labelKey].value"
      @change="handleRadioChange"
    >
      <el-radio v-for="(rad, i) in item.dataSource" :key="i" :label="rad.value">
        {{ rad.text }}
        <el-date-picker
          v-if="appModel[item.labelKey].value === 2 && rad.value === 2"
          v-model="appModel[item.labelKey].extValue"
          class="w200"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择日期时间"
          type="datetime"
          value-format="YYYY-MM-DD HH:mm:ss"
        />

        <el-popover v-if="rad.help" placement="right" trigger="hover" width="">
          <div class="popover-content-wrapper" v-html="rad.help"></div>
          <template #reference>
            <jwt-icon class="gd fs16" icon="question-fill" />
          </template>
        </el-popover>
      </el-radio>
    </el-radio-group>
    <el-select
      v-else-if="item.uiType === 'unit'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
    >
      <el-option
        v-for="(option, i) in item.unitOptions"
        :key="i"
        :label="option"
        :value="option"
      />
    </el-select>
    <el-checkbox-group
      v-else-if="item.uiType === 'checkbox'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      @change="hanldeItemChange($event, item)"
    >
      <el-checkbox
        v-for="ckb in item.dataSource"
        :key="ckb.value"
        :disabled="ckb.disabled"
        :label="ckb.value"
      >
        {{ ckb.text }}
        <span v-if="ckb.link" v-html="ckb.link"></span>
        <span class="cy">{{ ckb.help }}</span>
      </el-checkbox>
    </el-checkbox-group>
    <CheckboxAndRadio
      v-else-if="item.uiType === 'mode'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      :config="item"
    />
    <template v-else-if="item.uiType === 'table'">
      <el-table border :data="appModel[item.labelKey]" stripe>
        <el-table-column
          v-for="(column, index) in item.column"
          :key="index"
          :min-width="50"
          v-bind="column"
        />
      </el-table>
    </template>
    <el-input
      v-else-if="item.uiType === 'number'"
      v-model.number="appModel[item.labelKey]"
      v-bind="item.attrs"
      class="w200"
      type="number"
    >
      <template #append v-if="item.refUintLabelKey">
        {{ appModel[item.refUintLabelKey] }}
      </template>
    </el-input>
    <template v-else-if="item.uiType === 'volume'">
      <div>
        <el-input
          v-model.number="appModel[item.labelKey].length"
          v-bind="item.attrs"
          class="w200"
          placeholder="长"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
        <span class="plr8">X</span>
        <el-input
          v-model.number="appModel[item.labelKey].width"
          v-bind="item.attrs"
          class="w200"
          placeholder="宽"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
        <span class="plr8">X</span>
        <el-input
          v-model.number="appModel[item.labelKey].height"
          v-bind="item.attrs"
          class="w200"
          placeholder="高"
          type="number"
        >
          <template #append>厘米</template>
        </el-input>
      </div>
    </template>
    <el-cascader
      v-else-if="item.uiType === 'tree'"
      v-model="appModel[item.labelKey]"
      clearable
      :options="item.dataSource"
      :props="treeProps"
    />
    <ColorSelect
      v-else-if="item.uiType === 'colorSelect'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      @selectColor="hanldeItemChange($event, item)"
    />
    <DeliveryService
      v-else-if="item.uiType === 'fhfwTable'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      :config="item"
      :data-source="appModel"
      @change="hanldeItemChange($event, item)"
    />
    <InputTag
      v-else-if="item.uiType === 'inputTag'"
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      @change="hanldeItemChange($event, item)"
    />
    <jwt-editor
      v-else-if="item.uiType === 'richText'"
      v-model="appModel[item.labelKey]"
      class="ex mh0"
      :exclude-keys="excludeKeys"
      height="400"
      :toolbar-keys="toolbarKeys"
      :upload-url="uploadRichTextUrl"
    />
    <el-input
      v-else
      v-model="appModel[item.labelKey]"
      v-bind="item.attrs"
      type="text"
    />
  </slot>
  <div
    v-if="item?.promote?.right"
    class="ml20 g9 ex lh"
    v-html="item.promote.right"
  ></div>
  <div
    v-if="item?.promote?.bottom"
    class="w bg r4px plr10 mt5"
    v-html="item.promote.bottom"
  ></div>
</template>

<script>
  import { defineComponent, computed, ref, nextTick } from 'vue'
  import config, { uploadRichTextUrl } from '@/config/index'
  import { getAlbbApiGeneral, getAlbbFreightList } from '@/api/third'
  import ColorSelect from './colorSelect.vue'
  import InputTag from './inputTag.vue'
  import DeliveryService from './deliveryService.vue'
  import CheckboxAndRadio from './checkboxAndRadio.vue'
  import FreightSelect from './freightSelect.vue'
  export default defineComponent({
    name: 'AlbbFormItem',
    components: {
      ColorSelect,
      InputTag,
      DeliveryService,
      CheckboxAndRadio,
      FreightSelect,
    },
    props: {
      item: { type: Object, default: () => {} },
      formModel: { type: Object, default: () => {} },
      globalData: { type: Object, default: () => {} },
      shopid: { type: [Number, String], default: '' },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
      // console.log('props :>> ', props.item)
      const inputTagVal = ref(null)
      const inputTagValTips = ref(null)
      const treeProps = ref({
        value: 'value',
        label: 'text',
        children: 'children',
        multiple: true,
        checkStrictly: true,
      })
      const toolbarKeys = []
      const excludeKeys = [
        'blockquote',
        'group-more-style',
        'fontFamily',
        'lineHeight',
        'todo',
        'emotion',
        'uploadAttachment',
        'group-video',
        'insertTable',
        'codeBlock',
        'divider',
        'undo',
        'redo',
      ]
      const appModel = computed({
        get: () => {
          return props.formModel || {}
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      const handleRadioChange = (val) => {
        if (props.item.labelKey === 'upshelfTime') {
          emit('change', {
            value: val,
            extValue: props.formModel?.upshelfTime?.extValue || '',
          })
          return
        }
        emit('change', val)
      }

      const hanldeItemChange = (val, item) => {
        console.log('item', item)
        console.log('val', val)

        appModel.value[item.labelKey] = val
        nextTick(() => {
          emit('change', appModel.value[item.labelKey], item)
        })
      }

      const handleGetAlbbFreightList = () => {
        const params = {
          shop_id: props.shopid,
          category_id: props.formModel.category_id.at(-1),
        }
        console.log('params', params)
        // debugger
        getAlbbFreightList(params).then((res) => {
          console.log('getAlbbFreightList res', res)
        })
      }

      const handleSelectChange = (val, item) => {
        console.log('val :>> ', val)
        const { labelKey, dataSource } = item
        if (labelKey === 'freight') {
          // 运费模板
          const hasAsyncQueryType = dataSource.find(
            (item) => item.asyncQueryType && item.value === val
          )
          // 有异步请求的数据
          if (hasAsyncQueryType?.asyncQueryType) {
            handleGetAlbbFreightList()
          }
        }
      }

      return {
        appModel,
        treeProps,
        toolbarKeys,
        excludeKeys,
        inputTagVal,
        inputTagValTips,
        uploadRichTextUrl,
        handleRadioChange,
        handleSelectChange,
        hanldeItemChange,
      }
    },
  })
</script>

<style lang="scss">
  .popover-content-wrapper {
    img {
      display: block;
      max-width: 450px;
    }
  }
</style>
