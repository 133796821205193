<!--
 * @Author: WGL
 * @Date: 2023-12-11 11:53:34
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-14 17:32:38
 * @Description: 
-->
<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    center
    destroy-on-close
    :title="dialogTitle"
    top="3vh"
    width="80%"
    @close="handleCloseDialog"
  >
    <div>
      <el-steps :active="currentStep" align-center>
        <el-step
          v-for="(item, index) in stepList"
          :key="index"
          :title="item.title"
        />
      </el-steps>

      <div class="mt10 oy" style="min-height: 50vh">
        <el-form
          ref="formRef"
          :inline="false"
          label-width="80px"
          :model="formModel"
        >
          <!-- 选择类目 -->
          <div v-show="currentStep === 1">
            <el-form-item :label="t('商品类目')" required>
              <div>
                <!-- <el-select
                  v-model="value"
                  filterable
                  :loading="loading"
                  placeholder="请搜索商品类目"
                  remote
                  :remote-method="handleSearchRemoteMethod"
                  reserve-keyword
                  style="width: 240px"
                >
                  <el-option
                    v-for="item in state.searchList"
                    :key="item.categoryID"
                    :label="item.name"
                    :value="item.categoryID"
                  />
                </el-select> -->

                <el-cascader-panel
                  v-model="formModel.category_id"
                  clearable
                  filterable
                  :props="cascaderProps"
                  style="width: fit-content"
                  @change="handleCategoryChange"
                />
                <div class="fx mt10">
                  <div>常用类目：</div>
                  <template
                    v-for="(item, index) in searchAlbbCategoryListData"
                    :key="item.id"
                  >
                    <div
                      v-if="index < 15"
                      class="bg plr5 ptb2 r4px mr10 cp"
                      :class="[
                        formModel.category_id === item.pathVal ? 'bgt cf' : '',
                      ]"
                      @click="handleSelectSearchCategory(item)"
                    >
                      {{ item.name }}
                    </div>
                  </template>
                </div>
              </div>
            </el-form-item>
          </div>
          <!-- 产品信息 -->
          <div v-show="currentStep === 2" v-loading="state.getProductRule">
            <div>
              <el-form-item
                :label="state.currentCategorySupplyType.label"
                v-bind="state.currentCategorySupplyType.formAttrs"
              >
                <AlbbFormItem
                  v-if="state.currentCategorySupplyType.id"
                  :form-model="formModel"
                  :global-data="globalData"
                  :item="state.currentCategorySupplyType"
                  @change="handleFormItemChange"
                />
              </el-form-item>
            </div>
            <div class="bg p10 r4px">
              您选择的类目：{{ state.currentCategoryName }}
            </div>
            <el-tabs
              v-model="albbProductAttrs.activeName"
              @tab-click="handleTabClick"
            >
              <el-tab-pane
                v-for="tab in albbProductAttrs.navBarList"
                :key="tab.id"
                :label="tab.label"
                :name="tab.value"
                style="max-height: 65vh"
              />
            </el-tabs>
            <div class="oy plr10" style="max-height: 60vh">
              <div
                v-for="(tab, index) in albbProductAttrs.navBarList"
                :id="tab.value"
                :key="index"
              >
                <div class="fxmj pb10 bbor mb10">
                  <div class="fs18 b">{{ tab?.label }}</div>
                  <div
                    v-if="tab?.promote?.right"
                    v-html="tab.promote.right"
                  ></div>
                </div>
                <template v-for="item in tab.children" :key="item?.id">
                  <ProductPriceRange
                    v-if="item.labelKey === 'priceRange'"
                    :form-model="formModel"
                    :is-specs-sku="isSpecsSkuModel"
                    :item="item"
                  />
                  <ProductPriceSku
                    v-else-if="item.labelKey === 'skuTable'"
                    :form-model="formModel"
                    :is-specs-sku="isSpecsSkuModel"
                    :item="item"
                    :sale-prop-info="salePropInfo"
                  />
                  <template v-else-if="item.labelKey === 'officialLogistics'">
                    <ProductWeightSku
                      :form-model="formModel"
                      :item="item"
                      :sale-prop-info="salePropInfo"
                    />
                  </template>
                  <el-form-item
                    v-else-if="item.uiType != 'form'"
                    :label="item.label"
                    v-bind="item.formAttrs"
                  >
                    <AlbbFormItem
                      :form-model="formModel"
                      :global-data="globalData"
                      :item="item"
                      :shopid="currentShopId"
                      @change="handleFormItemChange($event, item)"
                    />
                  </el-form-item>
                  <div v-else class="fx mb10">
                    <div class="el-form-item__label w80">{{ item.label }}</div>
                    <div class="el-form-item__content fv">
                      <div class="fxmj ex w">
                        <el-text
                          v-if="item?.promote?.top"
                          type="info"
                          v-html="item?.promote?.top"
                        />
                      </div>
                      <el-row class="bg p10 r4px w">
                        <el-col
                          v-for="child in item.children"
                          :key="child?.id"
                          :span="
                            child?.span || (item.children?.length > 1 ? 12 : 24)
                          "
                        >
                          <el-form-item
                            :label="child.label"
                            v-bind="child.formAttrs"
                          >
                            <AlbbFormItem
                              :form-model="formModel"
                              :global-data="globalData"
                              :item="child"
                              :shopid="currentShopId"
                              @change="handleFormItemChange($event, child)"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <template #footer>
      <span>
        <el-button v-if="currentStep !== 1" @click="handleBack">
          {{ t('上一步') }}
        </el-button>
        <el-button
          :disabled="nextStepDisabled"
          type="primary"
          @click="handleNext"
        >
          {{ currentStep === stepList.length ? t('提交') : t('下一步') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import {
    defineProps,
    ref,
    reactive,
    computed,
    nextTick,
    defineExpose,
    toRaw,
  } from 'vue'
  import { t } from '@/utils/i18n'
  import {
    getAlbbProductRule,
    albbPushProduct,
    getAlbbProductCategoryTree,
    getAlbbSearchCategory,
    getAlbbProductGuarantee,
    getAlbbProductSubRule,
  } from '@/api/third'
  import dayjs from 'dayjs'
  import { serverOpenLink } from './config'
  import { ElMessage } from 'element-plus'
  // import { mockAlbbData } from './mock'
  const stepList = [
    { title: '选择类目', content: '基本信息' },
    { title: '产品信息', content: '产品信息' },
    // { title: '发布产品', content: '发布产品' },
  ]
  let timer = null
  const localAlbbCategoryList = LS.get('albbCategoryList')
  const searchAlbbCategoryList = ref({})
  searchAlbbCategoryList.value = localAlbbCategoryList || {}

  const props = defineProps({
    isAdd: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  })
  const localNodedata = {}
  const cascaderProps = {
    lazy: true,
    leaf: 'isLeaf',
    label: 'name',
    value: 'id',
    lazyLoad(node, resolve) {
      const { value } = node
      console.log('node', node)
      getAlbbProductCategoryTree({
        shop_id: props.info?.channel_info?.third_shop_id || '',
        category_id: value || '',
      }).then((res) => {
        const data = res.categoryInfo[0].childCategorys
        localNodedata[value] = data
        resolve(data)
      })
    },
  }
  const dialogVisible = ref(false)
  const formRef = ref(null)
  const currentStep = ref(1)
  const salePropInfo = ref({}) // 产品规格属性
  // const categoryId = ref([])
  const formModel = ref({
    category_id: '', // 类目id
  })

  const state = reactive({
    albbCategoryTree: [],
    searchList: [],
    productRuleConfig: {}, // 产品发布规则配置
    getProductRule: false, // 是否获取产品规格属性
    currentCategoryName: '', // 当前类目名称
    currentCategorySupplyType: {
      labelKey: '',
    }, // 当前类目供应商类型
    buyerProtectionConfig: {}, // 买家服务保障
  })

  // 当前店铺ID
  const currentShopId = computed(() => {
    return props.info?.channel_info?.third_shop_id || ''
  })

  function handleSearchRemoteMethod(val, key) {
    console.log('val', val)
    console.log('key', key)
    if (!val) {
      state.searchList = []
      return
    }
    // 防抖处理
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      getAlbbSearchCategory({
        shop_id: currentShopId.value,
        keyword: val,
      }).then((res) => {
        console.log('res', res)
        state.searchList = res.data
      })
    }, 300)
    return
  }

  // 商品属性数据
  const albbProductAttrs = ref({})
  const dialogTitle = computed(() => {
    return props.isAdd ? t('新增产品') : t('修改产品')
  })

  const globalData = computed(() => {
    return albbProductAttrs.value?.dataSource?.global
  })

  // 是否是按规格sku报价
  const isSpecsSkuModel = computed(() => {
    return formModel.value.quotationType === 1
  })

  const searchAlbbCategoryListData = computed(() => {
    return Object.values(searchAlbbCategoryList.value).sort(
      (a, b) => b.time - a.time
    )
  })

  // 下一步按钮是否禁用
  const nextStepDisabled = computed(() => {
    if (currentStep.value === 1) {
      return !formModel.value.category_id.at(-1)
    }
    return false
  })

  /**
   * 记录搜索历史
   * @param {*} value
   */
  function handleRecodeSearchCategory(
    shop_id,
    category_id,
    categoryInfo,
    result
  ) {
    result[category_id] = {
      ...categoryInfo,
      time: dayjs().valueOf(),
    }
    LS.set('albbCategoryList', result)
  }

  /**
   * 处理分类改变
   * @param {*} value
   */
  function handleCategoryChange(value) {
    console.log('value handleCategoryChange', value)
    if (value) {
      const category_id = value.at(-1) || ''
      const lastCategoryId2 = value.at(-2) || ''
      const lastCategoryTree = localNodedata[lastCategoryId2]
      const categoryInfo = lastCategoryTree.find(
        (item) => item.id === category_id
      )
      categoryInfo.pathVal = value
      formModel.value.category_id = value
      const shop_id = currentShopId.value
      handleRecodeSearchCategory(
        shop_id,
        category_id,
        categoryInfo,
        searchAlbbCategoryList.value
      )
      nextTick(() => {
        // console.log('localAlbbCategoryList', localAlbbCategoryList)
        // searchAlbbCategoryList.value = localAlbbCategoryList || {}
      })
    }
  }

  /**
   * 搜索分类
   * @param {*} key
   */
  function handleSelectSearchCategory(data) {
    formModel.value.category_id = data.pathVal
  }

  // 特殊处理供货方式
  function handleInitSupplyTypeData(res) {
    const currentCategorySupplyType = res?.data?.supplyType
      ? {
          ...res?.data?.supplyType.fields,
          labelKey: 'supplyType',
          uiType: 'checkbox',
          id: res?.data?.supplyType?.id || '',
        }
      : {}
    state.currentCategorySupplyType = currentCategorySupplyType
    formModel.value.supplyType = [1, 2]
  }
  /**
   * 获取阿里巴巴类目下的产品规则数据
   */
  function getAlbbProductRuleData(category_id) {
    state.getProductRule = true
    const params = {
      shop_id: currentShopId.value,
      category_id: category_id || formModel.value?.category_id.at(-1),
      scene: 'cbu',
    }
    getAlbbProductRule(params)
      .then(async (res) => {
        console.log('res :>> getAlbbProductRule', res)
        state.productRuleConfig = res
        state.currentCategoryName = res?.global?.systemParam?.catNames || ''
        const buyerProtectionConfig = await handleGetProductGuarantee()
        console.log('buyerProtectionConfig', buyerProtectionConfig)
        handleInitSupplyTypeData(res)
        handleAlbbProductRuleResult(res)
        nextTick(() => {
          state.getProductRule = false
        })
      })
      .catch(() => {
        state.getProductRule = false
      })
  }

  function scrollToSection(sectionId) {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  /**
   * tab 点击事件
   * @param {*} tab
   */
  function handleTabClick(tab) {
    console.log('tab', tab)
    nextTick(() => {
      console.log('handleTabClick :>> ', albbProductAttrs.value.activeName)
      scrollToSection(albbProductAttrs.value.activeName)
    })
  }

  /**
   * 下一步
   */
  function handleNext() {
    console.log('formModel', formModel.value)
    if (currentStep.value === stepList.length) {
      handleFormResult()
      return
    }
    currentStep.value += 1
    nextTick(() => {
      if (currentStep.value === 2) {
        getAlbbProductRuleData()
        // handleAlbbProductRuleResult(mockAlbbData)
      }
    })
  }

  /**
   * 上一步
   */
  function handleBack() {
    currentStep.value -= 1
  }

  /**
   * 关闭弹窗
   */
  const handleCloseDialog = () => {
    dialogVisible.value = false
    currentStep.value = 1
    formModel.value.category_id = []
  }

  /**
   * 打开弹窗
   */
  function handleShowDialog() {
    dialogVisible.value = true
  }

  /**
   * 获取数据源文本
   * @param {string} val 文本
   * @param {string} dataSource 数据源
   */
  function handleGetDataSourceText(val, dataSource) {
    let result = ''
    dataSource.map((item) => {
      // console.log('item handleGetDataSourceText', item, val)
      if (item.value === val) {
        result = item.text
      }
    })
    console.log('result', result)
    return result
  }

  /**
   * 获取表单类型
   * @param {string} type 类型
   * @param {string} labelKey 文本labelKey
   */
  function handleGetUiTypeVal(type, labelKey, dataSource) {
    let result = null
    const value = formModel.value[labelKey] || ''
    // console.log('handleGetUiTypeVal labelKey', type, labelKey, value)
    switch (type) {
      case 'input':
      case 'inputTag':
      case 'number':
      case 'select':
      case 'shelftime':
        result = value
        break
      case 'unit':
        result = {
          unit: value,
        }
        break
      case 'table':
        result = value
        break
      case 'checkbox':
        result = (value || [])?.map((v) => {
          return {
            value: v,
            text: v?.toString(),
          }
        })
        break
      case 'radio':
        result = {
          value: value,
          text: handleGetDataSourceText(value, dataSource),
        }
        break
      default:
        result = value
    }
    if (labelKey === 'tradeTemplate') {
      // 交易方式特殊处理
      return {
        steppayTemplateId: value || null,
        processTemplateIds: [],
        isWholeShop: false,
        wholeShopName: null,
      }
    } else if (labelKey === 'officialLogistics') {
      // 重量规格
      result.offerInfo = result?.offerInfo?.[0] || {}
    } else if (labelKey === 'description') {
      // 图文详情
      return {
        detailList: [
          {
            id: '0',
            title: '图文详情',
            contentUrl: null,
            content: value,
            isRequired: true,
          },
        ],
      }
    }
    return result
  }

  // 产品上传
  function handleSubmitalbbPushProduct(jsonData) {
    const params = {
      // product_id: props?.info?.id,
      shop_id: currentShopId.value,
      category_id: formModel.value.category_id.at(-1),
      jsonData: jsonData,
    }

    albbPushProduct(params).then(() => {})
  }

  /**
   * 获取ui类型
   * @param {*} filed
   */
  function getAlbbProductRuleUiType(filed, hasChild = true) {
    if (!filed.uiType) return 'input'
    if (filed.uiType.includes('selecttree')) {
      return 'tree'
    } else if (filed.colorProp) {
      return 'colorSelect'
    } else if (
      filed.uiType.includes('select') ||
      filed.uiType.includes('send_address') ||
      (filed.uiType.includes('checkbox') && filed?.dataSource?.length > 4)
    ) {
      return 'select'
    } else if (filed.uiType.includes('freight')) {
      return 'freightSelect'
    } else if (filed.uiType === 'input' && filed.enumProp) {
      return 'inputTag'
    } else if (filed.uiType.includes('title') || filed.uiType === 'input') {
      return 'input'
    } else if (filed.uiType.includes('radio')) {
      return 'radio'
    } else if (filed.uiType.includes('unit') && filed?.unitOptions?.length) {
      return 'unit'
    } else if (filed.uiType.includes('unit')) {
      return 'input'
    } else if (filed.uiType.includes('checkbox')) {
      return 'checkbox'
    } else if (filed.uiType.includes('mode')) {
      return 'mode'
    } else if (filed.uiType.includes('table')) {
      return 'table'
    } else if (filed.uiType.includes('number')) {
      return 'number'
    } else if (filed.uiType.includes('volume')) {
      return 'volume'
    } else if (filed.uiType.includes('shelftime')) {
      // 特殊处理定时开售
      return 'shelftime'
    } else if (filed.uiType.includes('description')) {
      // 特殊处理富文本
      return 'richText'
    } else if (filed.uiType.includes('official_logistics')) {
      // 特殊处理重量填写
      return 'skuWeightTable'
    } else if (filed.dataSource && hasChild) {
      return 'form'
    }
    return filed.uiType || 'input'
  }

  /**
   * 设置默认值
   * @param {*} fields
   * @param {*} labelKey
   * @param {*} uiType
   */
  function handleSetDefaultValue(fields, labelKey, uiType) {
    const options = fields.dataSource || fields.unitOptions || []
    // console.log('labelKey :>> handleSetDefaultValue', labelKey)
    // console.log('fields :>> handleSetDefaultValue', fields)
    const defaultValue = fields?.value?.value || ''
    switch (uiType) {
      case 'radio':
        formModel.value[labelKey] = fields.required
          ? defaultValue || options[0]?.value || ''
          : ''
        break
      case 'shelftime':
        formModel.value[labelKey] = fields.required
          ? { value: defaultValue || options[0]?.value || '', extValue: null }
          : ''
        break
      case 'select':
        if (fields.uiType === 'checkbox') {
          formModel.value[labelKey] = fields.required
            ? [options.at(0)] || []
            : []
        } else {
          formModel.value[labelKey] = fields.required
            ? fields?.value || options.at(0) || ''
            : ''
        }
        break
      case 'unit':
        formModel.value[labelKey] = fields.required ? options.at(0) : ''
        break
      case 'inputTag':
      case 'checkbox':
        formModel.value[labelKey] = []
        break
      case 'volume':
        formModel.value[labelKey] = {
          height: null,
          width: null,
          length: null,
        }
        break
      case 'table':
        if (labelKey === 'priceRange') {
          // 价格区间
          formModel.value[labelKey] = [
            {
              pricerange_beginAmount: null,
              pricerange_price: null,
            },
          ]
        } else if (labelKey === 'skuTable') {
          // 规格报价
          formModel.value[labelKey] = [
            {
              sku_props: [{}],
              sku_price: 0,
              sku_amountOnSale: 1000,
              sku_amountOrder: 0,
              sku_status: 1,
            },
          ]
        } else {
          formModel.value[labelKey] = []
        }
        break
      case 'freightSelect':
        formModel.value[labelKey] = fields?.value || {}
        break
      default:
        break
    }
    // 产品标题自动填充
    if (uiType === 'input' && labelKey === 'title') {
      formModel.value[labelKey] = props?.info?.product_info?.title || ''
    } else if (
      uiType === 'input' &&
      (labelKey === 'p-3151' || labelKey === 'p-9573')
    ) {
      // 型号
      formModel.value[labelKey] = props?.info?.product_info?.oem_code || ''
    } else if (
      uiType === 'input' &&
      (labelKey === 'p-2933' || labelKey === 'p-1398')
    ) {
      // 货号 || 外观尺寸
      let boxSize = ''
      if (
        props?.info?.product_info?.w ||
        props?.info?.product_info?.h ||
        props?.info?.product_info?.l
      ) {
        boxSize = `${props?.info?.product_info?.l}*${props?.info?.product_info?.w}*${props?.info?.product_info?.h}`
      }
      formModel.value[labelKey] = boxSize
    }
  }

  /**
   * 处理关联表单
   * @param {*} fields
   * @param {*} labelKey
   */
  function handleFormRelevance(fields, labelKey) {
    // console.log('labelKey :>> handleFormRelevance', labelKey, fields)
    let obj = {}
    if (labelKey === 'totalSales') {
      // 可售总量
      obj.refUintLabelKey = 'cbuUnit'
    } else if (labelKey === 'FHBZ') {
      obj.refUintLabelKey = 'cbuUnit'
      obj.refUintLabelKey1 = 'supplyType'
    }
    // 有关联子组件关系
    if (fields.supportLevelProp) {
      // handleGetAlbbProductSubRule()
    }
    // 产品属性
    if (labelKey === 'catProp') {
      // 是否有是否跨境出口专供属性
      const hasCrossBorderExportProp = fields.dataSource.some(
        (item) => item.name === 'p-182282223'
      )
      if (hasCrossBorderExportProp) {
        fields.dataSource.push({
          label: '主要下游平台',
          name: 'p-182282223-182318189',
          uiType: 'checkbox',
          dataSource: [
            {
              text: '速卖通',
              value: 114850607,
            },
            {
              text: '亚马逊',
              value: 3619339,
            },
            {
              text: 'wish',
              value: 4075098,
            },
            {
              text: 'ebay',
              value: 3250231,
            },
            {
              text: 'LAZADA',
              value: 647136512,
            },
            {
              text: '独立站',
              value: 573944140,
            },
            {
              text: '其他',
              value: 20213,
            },
          ],
        })
        fields.dataSource.push({
          label: '主要销售地区',
          name: 'p-182282223-193290002',
          uiType: 'checkbox',
          dataSource: [
            {
              text: '欧洲',
              value: 29148,
            },
            {
              text: '北美',
              value: 7048399,
            },
            {
              text: '南美',
              value: 4253677,
            },
            {
              text: '东南亚',
              value: 6465077,
            },
            {
              text: '东北亚',
              value: 8717434,
            },
            {
              text: '中东',
              value: 9052142,
            },
            {
              text: '非洲',
              value: 27018,
            },
            {
              text: '其他',
              value: 20213,
            },
          ],
        })
      }
    }
    return obj
  }

  /**
   * 获取form表单的属性
   * @param {*} fields
   * @param {*} labelKey
   */
  function handleAFormRuleFiledAttrs(fields, labelKey) {
    console.log('fields', fields)
    console.log('labelKey :>> ', labelKey)
    if (labelKey === 'saleProp') {
      salePropInfo.value = fields
    }
    const uiType = getAlbbProductRuleUiType(fields, false)
    handleSetDefaultValue(fields, labelKey, uiType)
    const attrsData = {
      formAttrs: {
        rules: [
          {
            required: fields.required,
            message: `'${fields.label}' 不能为空`,
            trigger: uiType === 'input' ? 'blur' : 'change',
          },
        ],
        prop: labelKey,
        labelWidth: fields.propType === 'item' ? '180px' : '110px',
      },
      attrs: {
        placeholder:
          fields.placeholder ||
          (uiType === 'select' ? '请选择' : '请输入') + fields.label,
        'show-word-limit': true,
        maxlength: fields.maxLength,
        clearable: true,
        supportImg: fields.supportImg,
      },
    }
    // checkbox 多选特殊处理
    if (fields.uiType === 'checkbox' && fields?.dataSource?.length > 0) {
      attrsData.attrs.multiple = true
      attrsData.attrs['value-key'] = 'value'
    }
    const extraData = {}
    // 特殊处理
    if (fields.uiType === 'cbu_freight') {
      // 运费模板
      const freightObj = {
        T: '使用运费模版',
        // C: '运费指导模版',
        F: '卖家承担运费',
      }
      const list = fields.dataSource
        .map((item) => {
          return {
            value: item,
            text: freightObj[item],
            asyncQueryType: item === 'T' ? 'fullFreightTemplateAsyncQuery' : '',
          }
        })
        .filter((item) => item.text)
      extraData.dataSource = list
    } else if (fields.uiType === 'cbu_trade_mode') {
      // 交易方式
      extraData.dataSource = fields.steppayTemplateList.map((v) => {
        return {
          ...v,
          value: v.id,
          text: v.name,
        }
      })
    }
    return { ...attrsData, ...extraData }
  }

  /**
   * 处理字段规则
   * @param {*} filedKey 当前字段key
   * @param {*} allFields 所有字段
   */
  function handleAlbbProductRuleFiled(filedKey, allFields) {
    const fields = allFields[filedKey]?.fields
    console.log('handleAlbbProductRuleFiled fields', fields)
    if (!fields) return null
    const labelKey = allFields[filedKey].id
    const formRelevance = handleFormRelevance(fields, labelKey)
    let result = {
      ...fields,
      labelKey,
      ...handleAFormRuleFiledAttrs(fields, labelKey),
      ...formRelevance,
      uiType: getAlbbProductRuleUiType(fields),
      children:
        fields.dataSource && fields.dataSource[0]?.uiType
          ? fields.dataSource.map((item) => {
              return {
                ...item,
                labelKey: item.name,
                label: item.label,
                uiType: getAlbbProductRuleUiType(item, false),
                ...handleAFormRuleFiledAttrs(item, item.name),
                ...handleFormRelevance(item, item.name),
              }
            })
          : null,
    }
    if (labelKey === 'buyerProtection') {
      // 卖家服务特殊处理
      result = {
        ...result,
        ...handleBuyerProtectionConfig(state.buyerProtectionConfig),
        uiType: 'form',
      }
    }

    return result
  }

  /**
   * 获取产品规则数据并组装数据
   */
  function handleAlbbProductRuleResult(albbData) {
    const { hierarchy, data: allFields } = albbData
    console.log('allFields', allFields)
    console.log('hierarchy', hierarchy)
    const { root, structure } = hierarchy
    // 隐藏的属性
    const hideAttrs = [
      'importProp', // 进口属性
      'bPrice', // L会员价设置
      'beginAmount', // 最小起订量
      'standardPrice', // 价格
      'processServiceTrade', // 定制项
      'relationOffer', //关联定制产品
      'anXinGouService', // 安心购·定制版
      // 'buyerProtection', // 买家保障
      'customExtraService', // 特色服务
      // 'officialLogistics', // 按规格设置重量
      'detailVideo', // 详情视频
      'lightCustom', // 属性定制
    ]
    const navBarList = []
    structure[root].forEach((item) => {
      if (item.includes('block') && item !== 'blockPrimary') {
        navBarList.push({
          ...allFields[item].fields,
          label: allFields[item].fields.label,
          value: allFields[item].id,
          children: structure[item]
            ?.map((filedKey) => {
              return handleAlbbProductRuleFiled(filedKey, allFields)
            })
            ?.filter((item) => {
              return (
                item &&
                !(
                  hideAttrs.includes(item.labelKey) ||
                  item?.labelKey?.includes('Hidden')
                )
              )
            }),
        })
      }
    })
    albbProductAttrs.value = {
      navBarList: navBarList,
      activeName: navBarList[0].value,
      dataSource: albbData,
    }
    console.log('navBarList', navBarList)
    console.log('formModel', formModel.value)
  }

  /**
   * 值改变
   * @param {*} val
   * @param {*} item
   */
  function handleFormItemChange(val, item) {
    console.log('val :>> ', val)
    console.log('item :>> ', item)
    if (item.labelKey === 'supplyType' && val.length === 0) {
      // 供货方式
      formModel.value[item.labelKey] = [1]
      ElMessage.error('请至少选择一项')
      return
    }
    formModel.value[item.labelKey] = val
  }

  /**
   * 买家保障服务数据处理
   */
  function handleBuyerProtectionConfig(data = {}) {
    const result = {
      promote: {},
      children: [],
    }
    if (data.isBpsOpen) {
      result.promote.top = `您已开通买保服务，当前保证金金额为：${
        data.balance / 100
      }元   <a herf='${data.detailLink}' target="_blank">保障服务首页</a>`
    }
    data?.ptsTagGroups?.forEach((item) => {
      const dataSource = item.tagModels.map((tag) => {
        const obj = {
          ...tag,
          text: tag.serviceName,
          value: tag.serviceCode,
          disabled: tag.status === 'APPLY_FAIL',
          link:
            tag.status === 'APPLY_FAIL'
              ? `<a class='g9' href="${
                  serverOpenLink?.[tag.serviceCode]?.link || ''
                }" target="_blank">申请开通</a>`
              : '',
          help: item.isMulti
            ? ''
            : serverOpenLink?.[tag.serviceCode]?.des || '',
        }
        return obj
      })
      if (!item.isMulti) {
        dataSource.push({
          text: '不支持',
          value: '',
        })
      }
      let uiType = item.isMulti ? 'checkbox' : 'radio'
      const labelKey = item.groupServiceCode
      if (labelKey === 'FHBZ') {
        // 发货服务特殊处理
        uiType = 'fhfwTable'
        formModel.value[labelKey] = [{ from: 1, value: 'drfh', end: '' }]
      } else {
        formModel.value[labelKey] = ''
      }
      const newItem = {
        ...item,
        labelKey,
        label: item.groupName,
        uiType,
        span: 24,
        dataSource: dataSource,
        ...handleAFormRuleFiledAttrs(item, labelKey),
        ...handleFormRelevance(item, labelKey),
      }
      result.children.push(newItem)
    })
    console.log('handleBuyerProtectionConfig result', result)
    return result
  }

  // 获取买家保障服务
  async function handleGetProductGuarantee(category_id) {
    return new Promise((resolve, reject) => {
      getAlbbProductGuarantee({
        shop_id: currentShopId.value,
        sub_category_id: category_id || formModel.value?.category_id.at(-1),
        product_id: '',
      })
        .then((res) => {
          console.log('getAlbbProductGuarantee res :>> ', res)
          state.buyerProtectionConfig = res
          // handleBuyerProtectionConfig(res)
          return resolve(res)
        })
        .catch(() => {
          return reject()
        })
    })
  }

  /**
   * 获取产品规则子规则
   */
  function handleGetAlbbProductSubRule() {
    const params = {
      shop_id: currentShopId.value,
      category_id: formModel.value?.category_id.at(-1),
      scene: 'cbu',
      dataBody: JSON.stringify({ global: state.productRuleConfig.global }),
    }
    getAlbbProductSubRule(params).then((res) => {
      console.log('getAlbbProductSubRule res', res)
    })
  }
  /**
   * 表单提交
   */
  async function handleFormResult() {
    console.log('formRef :>> ', formRef.value)
    const validForm = await formRef.value.validate((valid) => {
      return valid
    })
    console.log('validForm :>> ', validForm)
    // if (!validForm) return
    const obj = {}
    albbProductAttrs.value?.navBarList?.forEach((item) => {
      if (
        item.children
        // item.value === 'blockLogistics'
        // (item.value === 'blockTradeInfo' || item.value === 'blockProps')
      ) {
        console.log('handleFormResult', item)
        item.children.forEach((filed) => {
          const { labelKey, uiType, dataSource } = filed
          if (filed.uiType === 'form') {
            obj[labelKey] = {}
            filed?.children?.map((v) => {
              const {
                labelKey: labelKey2,
                uiType: uiType2,
                dataSource: dataSource2,
              } = v
              if (labelKey2) {
                obj[labelKey][labelKey2] = handleGetUiTypeVal(
                  uiType2,
                  labelKey2,
                  dataSource2
                )
              }
            })
          } else {
            obj[labelKey] = handleGetUiTypeVal(uiType, labelKey, dataSource)
          }
        })
      }
    })
    console.log('obj', obj)
    console.log('dataSource', albbProductAttrs.value.dataSource)
    // 买家服务特殊处理
    if (obj.buyerProtection) {
      const resut = []
      state.buyerProtectionConfig.ptsTagGroups.forEach((v) => {
        const labelKey = v.groupServiceCode
        const isMulti = v.isMulti
        const value = obj.buyerProtection[labelKey]
        let steps = []
        if (isMulti) {
          steps = value
        } else if (labelKey === 'FHBZ') {
          steps = toRaw(value)
        } else {
          steps = value?.value ? [value] : []
        }
        resut.push({
          logicGroupId: v.groupId,
          offerMode: v.tagMode,
          steps,
        })
      })
      obj.buyerProtection = {
        selectedServices: {
          dsc: resut,
        },
      }
    }
    const catNamer =
      state.productRuleConfig?.data?.catNamer?.fields?.value || ''
    const jsonData = {
      formValues: {
        ...obj,
        catNamer: toRaw(catNamer),
        supplyType: formModel.value.supplyType,
      },
      global: globalData.value,
    }
    console.log('jsonData', jsonData)
    // handleSubmitalbbPushProduct(jsonData)
  }

  defineExpose({
    handleShowDialog,
    handleCloseDialog,
  })
</script>
<script>
  export default {
    name: 'AlbbPublishProduct',
  }
</script>
<style scoped></style>
