<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2025-01-03 13:54:47
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-10 10:39:45
 * @FilePath: /erp-admin/src/componentBase/AlbbPublishProduct/components/deliveryService.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="w">
    <p class="cy">
      现货发货获取流量扶持，一档时效越短数量越多，流量扶持越多；买家下单数量超出一档或商品仅勾选定制视为定制交易
    </p>
    <el-table border :data="tableData" stripe>
      <el-table-column
        v-for="column in columns"
        :key="column.id"
        align="center"
        :label="column.label"
        :prop="column.prop"
        :width="column.width"
      >
        <template #default="{ row, $index }">
          <!-- 操作 -->
          <template v-if="column.prop === 'operation'">
            <el-button
              :disabled="addDisabled"
              link
              @click="handleAddServiceRange($index)"
            >
              <span class="fs20">+</span>
            </el-button>
            <el-button
              class="fs24"
              :disabled="!$index"
              link
              @click="handleRemoveServiceRange(item, $index)"
            >
              <span class="fs24">-</span>
            </el-button>
          </template>
          <!-- 购买数量 -->
          <template v-else-if="column.prop === 'num'">
            <div class="fxmc">
              <el-input
                v-model="row.from"
                class="w150"
                disabled
                placeholder="请输入"
                type="number"
              >
                <template #append v-if="config.refUintLabelKey">
                  {{ dataSource[config.refUintLabelKey] }}
                </template>
              </el-input>
              <div class="plr5">-</div>
              <el-input
                v-if="$index === tableData.length - 1"
                class="w150"
                :controls="false"
                controls-position="both"
                :disabled="$index === tableData.length - 1"
                :max="999999"
                :min="0"
                placeholder="库存上限"
                value="库存上限"
              />
              <el-input
                v-else
                v-model="row.end"
                class="w150"
                placeholder="请输入"
                type="number"
                @change="handleServiceNumEnd($index)"
              >
                <template #append v-if="config.refUintLabelKey">
                  {{ dataSource[config.refUintLabelKey] }}
                </template>
              </el-input>
            </div>
          </template>
          <!-- 发货时间 -->
          <template v-else-if="column.prop === 'time'">
            <el-select
              v-model="row.value"
              placeholder="请选择"
              @change="handleChangeSendTime"
            >
              <el-option
                v-for="item in config.dataSource"
                :key="item.serviceCode"
                :label="item.serviceName"
                :value="item.serviceCode"
              />
            </el-select>
          </template>
          <!-- 预览 -->
          <template v-else-if="column.prop === 'preview'">
            <div>{{ handleRowTips(row, $index) }}</div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
  import { set } from 'lodash-es'
  import { defineProps, defineEmits, nextTick, computed } from 'vue'
  const props = defineProps({
    modelValue: { type: [String, Number], default: null },
    config: { type: Object, default: () => {} },
    dataSource: { type: Object, default: () => {} },
  })
  const emit = defineEmits(['update:modelValue', 'change'])

  console.log('props deliveryService', props)
  // const tableData = ref([{ from: 1, value: 'drfh', end: '' }])
  const tableData = computed({
    get() {
      return props.modelValue
        ? props.modelValue
        : [{ from: 1, value: 'drfh', end: '' }]
    },
    set(value) {
      emit('update:modelValue', value)
    },
  })
  const columns = [
    { prop: 'operation', label: '操作', width: '100' },
    { prop: 'num', label: '购买数量', width: '350' },
    { prop: 'time', label: '发货时间', width: '200' },
    {
      prop: 'preview',
      label: '预览（可根据买家购买的不同数量设置不同的发货时间）',
    },
  ]

  const allDisabled = computed(() => {
    const refUintLabelKey1 = props.config.refUintLabelKey1
    console.log('refUintLabelKey1', refUintLabelKey1)
    console.log(
      'props.dataSource?.[refUintLabelKey1]?.includes(2)',
      props.dataSource?.[refUintLabelKey1]?.includes(2)
    )
    return !props.dataSource?.[refUintLabelKey1]?.includes(2)
  })

  const addDisabled = computed(() => {
    return tableData.value.length === 3 || allDisabled.value
  })
  console.log('addDisabled', addDisabled)

  function handleChangeNum() {
    let result = []
    tableData.value.forEach((item, index) => {
      if (index) {
        const lastItem = result[index - 1]
        const obj = {
          ...item,
          from: +lastItem.end + 1,
          end: index === 2 ? '' : item.end < item.from ? item.from : item.end,
        }
        result.push(obj)
      } else {
        result.push(item)
      }
    })
    tableData.value = result
    handleEmitData()
  }
  function handleAddServiceRange(index) {
    tableData.value = tableData.value.map((item) => {
      return {
        ...item,
        end: item.end ? item.end : +item.from + 1,
      }
    })
    nextTick(() => {
      const lastItem = tableData.value.at(-1)
      const from = +lastItem.end + 1 || +lastItem.from + 2
      tableData.value.push({
        from,
        end: '',
      })
      handleEmitData()
    })
  }

  function handleRemoveServiceRange(item, index) {
    tableData.value.splice(index, 1)
    nextTick(() => {
      handleChangeNum()
    })
  }

  function handleServiceNumEnd(index) {
    nextTick(() => {
      handleChangeNum()
    })
  }

  // 处理提示预览文本
  function handleRowTips(row, index) {
    const unit = props.dataSource[props.config.refUintLabelKey]
    let sendTime = ''
    if (row.value) {
      sendTime = props.config.dataSource.find(
        (item) => item.serviceCode === row.value
      ).serviceName
    } else {
      return ''
    }
    if (index === tableData.value.length - 1) {
      return `${row.from}${unit}以上：承诺${sendTime}`
    } else {
      return `${row.from}-${row.end}${unit}：承诺${sendTime}`
    }
  }

  // 发货时间
  function handleChangeSendTime() {
    handleEmitData()
  }

  function handleEmitData() {
    nextTick(() => {
      console.log('handleEmitData tableData.value', tableData.value)
      emit('change', tableData.value)
    })
  }
</script>

<style lang="scss" scoped></style>
