<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-19 16:18:34
 * @Description: 
-->
<template>
  <div>
    <div v-if="type === 'quality'">
      <span>{{ volumeText }} {{ volumeUnit }}</span>
      <span class="plr2">/</span>
      <span>{{ volumeTextTransform }}</span>
    </div>
    <div v-else>
      {{ currentBoxInfo?.quantity || 1 }}{{ info?.unit || '' }}/ {{ volumeText
      }}{{ volumeUnit }}
    </div>
    <!-- 净重/毛重 -->
    <div>
      <span v-if="type !== 'quality'">{{ wieghtText }} /</span>
      {{ numberToFixed(currentBoxInfo?.weight, 3) || '-' }}
      {{ defaultWeightUnit }}
    </div>
    <div>{{ volumeSourceText }} {{ defaultUnit }}</div>
  </div>
</template>

<script setup>
  // 基础产品规格组件
  import { defineProps, computed } from 'vue'
  import { numberToFixed, convertVolume, handleVolume } from '@/utils/index'
  const props = defineProps({
    info: { type: Object, default: () => {} },
    type: { type: String, default: 'quality' }, // quality:品规 box:箱规 inner:盒规
  })

  const currentBoxInfo = computed(() => {
    if (props.type === 'box') {
      return {
        l: props.info?.box_l,
        w: props.info?.box_w,
        h: props.info?.box_h,
        weight: props.info?.box_rough_weight,
        quantity: props.info?.box_quantity,
        display_length_unit: props.info?.box_length_unit,
        display_weight_unit: props.info?.box_weight_unit,
      }
    } else if (props.type === 'inner') {
      return {
        l: props.info?.inner_box_l,
        w: props.info?.inner_box_w,
        h: props.info?.inner_box_h,
        weight: props.info?.rough_weight,
        quantity: props.info?.inner_box_quantity,
        display_length_unit: props.info?.inner_box_length_unit,
        display_weight_unit: props.info?.inner_box_weight_unit,
      }
    }
    return {
      ...props.info,
      display_length_unit: props.info?.length_unit,
      display_weight_unit: props.info?.weight_unit,
    }
  })

  // 尺寸显示单位
  const defaultUnit = computed(() => {
    return currentBoxInfo.value?.display_length_unit || 'cm'
  })
  // 净重
  const wieghtText = computed(() => {
    return numberToFixed(
      (+currentBoxInfo.value?.quantity || 1) * (+props?.info?.weight || 0) ||
        '0.000',
      3
    )
  })
  // 重量显示单位
  const defaultWeightUnit = computed(() => {
    return currentBoxInfo.value?.display_weight_unit || 'kg'
  })
  // 体积单位显示
  const volumeUnit = computed(() => {
    return defaultUnit.value === 'cm' ? 'm³' : 'ft³'
  })
  // 尺寸
  const volumeSourceText = computed(() => {
    return `${currentBoxInfo.value?.l || ''}L-${
      currentBoxInfo.value?.w || ''
    }W-${currentBoxInfo.value?.h || ''}H`
  })
  // 体积
  const volumeText = computed(() => {
    return handleVolume(currentBoxInfo.value, defaultUnit.value)
  })

  // 体积转换
  const volumeTextTransform = computed(() => {
    return convertVolume(volumeText.value, defaultUnit.value, true)
  })
</script>
<script>
  export default {
    name: 'BaseSingleSpecs',
  }
</script>
<style lang="scss" scoped></style>
