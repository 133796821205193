// 颜色配置
export const colorConfigDefault = [
    {
        text: '白色系',
        bg: 'rgb(255, 255, 255)',
        child: [
            { text: '白色', bg: 'rgb(255, 255, 255)', isSelect: false },
            { text: '乳白色', bg: 'rgb(255, 251, 240)', isSelect: false },
            { text: '米白色', bg: 'rgb(238, 222, 176)', isSelect: false },
            { text: '象牙白', bg: 'rgb(255, 255, 239)', isSelect: false },
        ],
    },
    {
        text: '灰色系',
        bg: 'rgb(128, 128, 128)',
        child: [
            { text: '灰色', bg: 'rgb(128, 128, 128)', isSelect: false },
            { text: '浅灰色', bg: 'rgb(228, 228, 228)', isSelect: false },
            { text: '深灰色', bg: 'rgb(102, 102, 102)', isSelect: false },
            { text: '银色', bg: 'rgb(192, 192, 192)', isSelect: false },
            { text: '铅色', bg: 'rgb(114, 119, 123)', isSelect: false },
            { text: '奶奶灰', bg: 'rgb(234, 234, 234)', isSelect: false },
            { text: '石板灰', bg: 'rgb(112, 127, 144)', isSelect: false },
            { text: '深空灰', bg: 'rgb(169, 169, 169)', isSelect: false },
            { text: '石墨色', bg: 'rgb(105, 103, 100)', isSelect: false },
            { text: '银灰色', bg: 'rgb(192, 192, 192)', isSelect: false },
        ],
    },
    {
        text: '黑色系',
        bg: 'rgb(0,0,0)',
        child: [{ text: '黑色', bg: 'rgb(0, 0, 0)', isSelect: false }],
    },
    {
        text: '红色系',
        bg: 'rgb(255, 0, 0)',
        child: [
            { text: '红色', bg: 'rgb(255, 0, 0)', isSelect: false },
            { text: '桔红色', bg: 'rgb(255, 117, 0)', isSelect: false },
            { text: '粉红色', bg: 'rgb(255, 182, 192)', isSelect: false },
            { text: '玫红色', bg: 'rgb(223, 27, 118)', isSelect: false },
            { text: '藕色', bg: 'rgb(238, 208, 216)', isSelect: false },
            { text: '西瓜红', bg: 'rgb(239, 87, 84)', isSelect: false },
            { text: '酒红色', bg: 'rgb(153, 0, 1)', isSelect: false },
            { text: '桃色', bg: 'rgb(245, 143, 152)', isSelect: false },
            { text: '深红色', bg: 'rgb(139, 1, 0)', isSelect: false },
            { text: '砖红色', bg: 'rgb(177, 35, 34)', isSelect: false },
            { text: '橙红色', bg: 'rgb(254, 69, 0)', isSelect: false },
            { text: '珊瑚色', bg: 'rgb(255, 127, 80)', isSelect: false },
            { text: '洋红色', bg: 'rgb(255, 1, 255)', isSelect: false },
            { text: '亮粉', bg: 'rgb(255, 104, 180)', isSelect: false },
            { text: '胭脂色', bg: 'rgb(179, 67, 74)', isSelect: false },
            { text: '深粉红', bg: 'rgb(255, 19, 147)', isSelect: false },
            { text: '蔷薇色', bg: 'rgb(240, 91, 114)', isSelect: false },
            { text: '猩红色', bg: 'rgb(220, 21, 61)', isSelect: false },
        ],
    },
    {
        text: '黄色系',
        bg: 'rgb(255, 255, 0)',
        child: [
            { text: '黄色', bg: 'rgb(255, 255, 0)', isSelect: false },
            { text: '浅黄色', bg: 'rgb(255, 255, 224)', isSelect: false },
            { text: '柠檬黄', bg: 'rgb(255, 237, 66)', isSelect: false },
            { text: '桔色', bg: 'rgb(255, 165, 0)', isSelect: false },
            { text: '明黄色', bg: 'rgb(255, 255, 0)', isSelect: false },
            { text: '金色', bg: 'rgb(255, 215, 2)', isSelect: false },
            { text: '荧光黄', bg: 'rgb(234, 255, 86)', isSelect: false },
            { text: '姜黄色', bg: 'rgb(255, 199, 116)', isSelect: false },
            { text: '香槟色', bg: 'rgb(247, 247, 209)', isSelect: false },
            { text: '杏色', bg: 'rgb(246, 238, 213)', isSelect: false },
            { text: '卡其色', bg: 'rgb(195, 177, 145)', isSelect: false },
            { text: '土黄色', bg: 'rgb(236, 189, 100)', isSelect: false },
            { text: '橙色', bg: 'rgb(255, 165, 0)', isSelect: false },
            { text: '肤色', bg: 'rgb(253, 220, 189)', isSelect: false },
            { text: '小麦色', bg: 'rgb(255, 194, 13)', isSelect: false },
            { text: '向日葵色', bg: 'rgb(255, 194, 13)', isSelect: false },
        ],
    },
    {
        text: '绿色系',
        bg: 'rgb(0, 128, 0)',
        child: [
            { text: '绿色', bg: 'rgb(0, 128, 2)', isSelect: false },
            { text: '浅绿色', bg: 'rgb(144, 238, 143)', isSelect: false },
            { text: '墨绿色', bg: 'rgb(0, 99, 0)', isSelect: false },
            { text: '军绿色', bg: 'rgb(93, 117, 42)', isSelect: false },
            { text: '翠绿色', bg: 'rgb(10, 162, 69)', isSelect: false },
            { text: '荧光绿', bg: 'rgb(36, 250, 10)', isSelect: false },
            { text: '青色', bg: 'rgb(4, 224, 158)', isSelect: false },
            { text: '草绿色', bg: 'rgb(123, 252, 0)', isSelect: false },
            { text: '黄绿色', bg: 'rgb(155, 204, 49)', isSelect: false },
            { text: '碧绿', bg: 'rgb(127, 255, 211)', isSelect: false },
            { text: '绿宝石', bg: 'rgb(64, 224, 208)', isSelect: false },
            { text: '海洋绿', bg: 'rgb(47, 138, 88)', isSelect: false },
            { text: '橄榄色', bg: 'rgb(128, 128, 0)', isSelect: false },
            { text: '橄榄绿', bg: 'rgb(79, 95, 20)', isSelect: false },
            { text: '抹茶色', bg: 'rgb(183, 186, 107)', isSelect: false },
            { text: '孔雀绿', bg: 'rgb(25, 115, 131)', isSelect: false },
        ],
    },
    {
        text: '蓝色系',
        bg: 'rgb(0, 0, 254)',
        child: [
            { text: '蓝色', bg: 'rgb(8, 0, 254)', isSelect: false },
            { text: '天蓝色', bg: 'rgb(66, 206, 246)', isSelect: false },
            { text: '孔雀蓝', bg: 'rgb(2, 163, 197)', isSelect: false },
            { text: '宝蓝色', bg: 'rgb(64, 105, 225)', isSelect: false },
            { text: '深蓝色', bg: 'rgb(3, 23, 144)', isSelect: false },
            { text: '湖蓝色', bg: 'rgb(47, 223, 243)', isSelect: false },
            { text: '浅蓝色', bg: 'rgb(210, 240, 244)', isSelect: false },
            { text: '藏青色', bg: 'rgb(46, 78, 126)', isSelect: false },
            { text: '海蓝色', bg: 'rgb(77, 104, 116)', isSelect: false },
            { text: '蔚蓝色', bg: 'rgb(240, 255, 255)', isSelect: false },
        ],
    },
    {
        text: '紫色系',
        bg: 'rgb(128, 0, 128)',
        child: [
            { text: '紫色', bg: 'rgb(128, 0, 128)', isSelect: false },
            { text: '浅紫色', bg: 'rgb(237, 223, 230)', isSelect: false },
            { text: '深紫色', bg: 'rgb(67, 6, 83)', isSelect: false },
            { text: '紫红色', bg: 'rgb(139, 0, 97)', isSelect: false },
            { text: '紫罗兰', bg: 'rgb(238, 130, 238)', isSelect: false },
            { text: '暗紫罗兰', bg: 'rgb(148, 0, 211)', isSelect: false },
            { text: '深洋红色', bg: 'rgb(139, 1, 139)', isSelect: false },
            { text: '紫兰色', bg: 'rgb(75, 0, 130)', isSelect: false },
            { text: '薰衣草紫', bg: 'rgb(230, 230, 250)', isSelect: false },
        ],
    },
    {
        text: '棕色系',
        bg: 'rgb(124, 75, 0)',
        child: [
            { text: '咖啡色', bg: 'rgb(96, 58, 17)', isSelect: false },
            { text: '深棕色', bg: 'rgb(124, 75, 0)', isSelect: false },
            { text: '浅棕色', bg: 'rgb(179, 93, 68)', isSelect: false },
            { text: '栗色', bg: 'rgb(96, 40, 30)', isSelect: false },
            { text: '深卡其布色', bg: 'rgb(189, 182, 107)', isSelect: false },
            { text: '巧克力色', bg: 'rgb(209, 105, 30)', isSelect: false },
            { text: '褐色', bg: 'rgb(133, 91, 1)', isSelect: false },
            { text: '驼色', bg: 'rgb(167, 132, 98)', isSelect: false },
            { text: '焦糖色', bg: 'rgb(176, 119, 72)', isSelect: false },
            { text: '古铜色', bg: 'rgb(184, 114, 52)', isSelect: false },
            { text: '红褐色', bg: 'rgb(89, 35, 33)', isSelect: false },
            { text: '琥珀色', bg: 'rgb(183, 110, 64)', isSelect: false },
            { text: '玫瑰金', bg: 'rgb(202, 133, 106)', isSelect: false },
        ],
    },
    {
        text: '透明系',
        bg: 'transparent',
        child: [{ text: '透明', bg: 'transparent', isSelect: false }],
    },
]
// 买家服务开通链接
export const serverOpenLink = {
    qtbh: {
        title: "7天包换",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=qtbh',
        des: "卖家在承诺买家保障服务的基础上，自愿选择向买家提供“7天包换”服务。在买家使用在线方式订购支持“7天包换 ”服务的商品后，卖家承诺在买家签收货物后的7天内，对无人为损坏的商品提供更换服务。如卖家未履行前述承诺，买家可在指定期限内发起维权，并申请赔付。"
    },
    qtwlybt: {
        title: "7天无理由退",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=qtwlybt',
        des: "卖家承诺买家签收货物后7天内，在“商品完好”的情形下，向买家提供退货服务。"
    },
    czbz: {
        title: "材质保障",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=czbz',
        des: "卖家在承诺买家保障服务的基础上，自愿选择向买家提供的保障服务。卖家承诺该款产品均符合与买家事先协商确认的材质要求，否则将按照货款金额的20%（但最高不超过10000元）对买家进行赔偿。"
    },
    psbj: {
        title: "破损包赔",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=psbj',
        des: "卖家在承诺买家保障服务的基础上，自愿选择向买家提供“破损包赔”服务。在买家使用在线方式订购卖家出售的产品后，如产品存在破损，卖家承诺在保障时效内免费向买家补寄一次，如补寄后买家依旧无法获得完好的产品，卖家需向买家提供破损产品的退货退款服务。"
    },
    shbp: {
        title: "少货必赔",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=shbp',
        des: "如卖家未能在指定期间内提供相关证据材料，或相关证据材料明显无效，阿里巴巴判定买家赔付申请成立，卖家同意按照本协议之约定向买家赔偿其受到的损失，赔偿金额为买家已实际支付的短少产品价款（仅为配件短少时亦应就整套产品价款进行赔偿）。此等情形下，阿里巴巴有权动用卖家的保证金或通知支付宝公司自卖家的支付宝账户直接扣除相应金额款项先行赔付给买家。"
    },
    hdbp: {
        title: "坏单包赔",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=hdbp',
        des: "阿里巴巴判定买家赔付申请成立，卖家同意按照本协议之约定向买家赔偿其受到的损失，赔偿金额为买家主张的坏单数量的产品价款。此等情形下，阿里巴巴有权动用卖家的保证金或通知支付宝公司自卖家的支付宝账户直接扣除相应金额款项先行赔付给买家。"
    },
    gfzs: {
        title: "官方直送",
        link: 'https://bao.1688.com/pts/pts_deposit_open.html?serviceCode=gfzs',
        des: `"卖家开通“官方直送”保障服务后，承诺向买家同时提供“官方直送”保障服务。商品需要满足以下条件，该商品将会透出“官方直送”保障服务标： 
            （1）您在商品上勾选“官方直送”保障服务；
            （2）该商品使用“运费指导模板”；
            （3）卖家该商品近30天交易单使用“官方直送”服务发货比例达到90%及以上（近30天交易单量10单及以上）。
            如果卖家未履行该商品未使用“运费指导模板”或近30天交易单使用“官方直送”服务发货比例达到90%及以上（近30天交易单量10单及以上）的承诺，该商品将被清退。"`
    }
}