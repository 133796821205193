<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2024-12-30 09:56:45
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-30 11:11:49
 * @FilePath: /erp-admin/src/componentBase/AlbbPublishProduct/components/skuAttrsAdd.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="fx fxwp w">
      <div
        class="fx mw0 w200 mr10 mb10"
        v-for="(item, index) in skuAttrs"
        :key="index"
      >
        <el-input v-model="item.text" class="ex" placeholder="请输入" />
        <div v-if="supportImg" class="wh30 bor r2px fxmc ml5 bcf">
          <jwt-icon icon="add-large-fill" />
        </div>
        <el-button type="primary" class="ml5" link @click="handleDelete(index)">
          删除
        </el-button>
      </div>
      <el-input
        v-model="inputModel"
        class="w150 mb10"
        :placeholder="placeholder"
        size="normal"
        @change="handleAddSkuAttrs"
      />
    </div>
    <div v-if="inputTagValTips" class="cd fs13">{{ inputTagValTips }}</div>
  </div>
</template>

<script setup>
  import { ref, defineProps, watchEffect, defineEmits, nextTick } from 'vue'
  import { ElMessage } from 'element-plus'
  const props = defineProps({
    modelValue: { type: Array, default: () => [] },
    supportImg: { type: Boolean, default: false }, // 是否支持图片
    placeholder: { type: String, default: '' },
  })

  const emit = defineEmits(['change'])
  const skuAttrs = ref([])
  const inputModel = ref('')

  /**
   * 添加属性
   * @param val
   */
  function handleAddSkuAttrs(val) {
    const isExist = skuAttrs.value.some((item) => item.text === val)
    if (isExist) {
      return ElMessage.warning('属性已存在')
    }
    skuAttrs.value.push({
      text: inputModel.value,
      imgUrl: '',
    })
    inputModel.value = ''
    emitData()
  }

  /**
   * 删除属性
   * @param index
   */
  function handleDelete(index) {
    skuAttrs.value.splice(index, 1)
    emitData()
  }

  function emitData() {
    nextTick(() => {
      emit('change', skuAttrs.value)
    })
  }

  watchEffect(() => {
    if (props.modelValue.length > 0) {
      skuAttrs.value = props.modelValue
    }
  })
</script>

<style lang="scss" scoped></style>
