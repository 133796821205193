<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2025-01-06 13:49:04
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-10 09:39:55
 * @FilePath: /erp-admin/src/componentBase/AlbbPublishProduct/components/freightSelect.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="w">
    <el-select
      v-model="appModel.freightType"
      v-bind="config.attrs"
      class="w300"
      @change="handleSelectChange"
    >
      <el-option
        v-for="(option, i) in config.dataSource"
        :key="i"
        :label="option.text"
        :value="option.value"
      />
    </el-select>
    <el-select
      v-if="appModel.freightType === 'T'"
      v-model="appModel.freightId"
      class="w300 ml10"
      @change="handleSelectTempChange"
    >
      <el-option
        v-for="(option, i) in freightList"
        :key="i"
        :label="option.name"
        :value="option.id"
      />
    </el-select>
  </div>
</template>

<script setup>
  import {
    defineProps,
    computed,
    defineEmits,
    onMounted,
    ref,
    nextTick,
  } from 'vue'
  import { getAlbbFreightList } from '@/api/third'

  const props = defineProps({
    modelValue: { type: [String, Number], default: null },
    config: { type: Object, default: () => {} },
    dataSource: { type: Object, default: () => {} },
    shopid: { type: [Number, String], default: '' },
  })
  const emit = defineEmits(['update:modelValue', 'change'])

  const appModel = computed({
    get: () => {
      return (
        props.modelValue || {
          freightType: 'T',
          freightId: null,
          canQuitTime: 0,
          joinDate: null,
          isOfferFreeShipping: null,
        }
      )
    },
    set: (value) => {
      emit('update:modelValue', value)
    },
  })

  const freightList = ref([])

  function handleEmitData() {
    nextTick(() => {
      emit('change', appModel.value)
      console.log('appModel.value', appModel.value)
    })
  }

  function handleSelectChange(val) {
    console.log('val', val)
    handleEmitData()
  }

  function handleSelectTempChange(val) {
    console.log('val', val)
    handleEmitData()
  }
  // 获取运费模板列表
  const handleGetAlbbFreightList = () => {
    const params = {
      shop_id: props.shopid,
      category_id: props.dataSource.category_id.at(-1),
    }
    getAlbbFreightList(params).then((res) => {
      const result = res?.freightTemplates || []
      freightList.value = result
      appModel.value = {
        ...(appModel.value || {}),
        freightId: result[0]?.id || '',
      }
      handleEmitData()
    })
  }

  onMounted(() => {
    handleGetAlbbFreightList()
  })
</script>

<style lang="scss" scoped></style>
