<template>
  <el-form-item :label="item.label" v-bind="item.formAttrs">
    <div class="w">
      <div v-if="item?.promote?.top" class="g9" v-html="item.promote.top"></div>
      <div>
        <el-radio-group v-model="appModel[item.labelKey].showLogisticsCategory">
          <el-radio
            v-for="item in logisticsCategory"
            :key="item.key"
            :value="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <el-table
        v-show="appModel[item.labelKey].showLogisticsCategory === 'sku'"
        border
        :data="appModel[item.labelKey].skuInfo"
        stripe
      >
        <el-table-column
          v-for="(column, index) in tableColumns"
          :key="index"
          align="center"
          :min-width="50"
          v-bind="column"
        >
          <template #default="{ row }" v-if="column.uiType">
            <template v-if="column.uiType === 'input'">
              <el-input-number
                v-model="row[column.prop]"
                :controls="false"
                controls-position="both"
                :max="999999"
                :min="0"
                placeholder="请输入"
                :step="1"
              />
            </template>
            <template v-else-if="column.uiType === 'text'">
              <div>
                {{ handleGetSkuAttrVal(row, column) }}
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-show="appModel[item.labelKey].showLogisticsCategory === 'item'"
        border
        :data="appModel[item.labelKey].offerInfo"
        stripe
      >
        <el-table-column
          v-for="(column, index) in defaultColumns"
          :key="index"
          align="center"
          :min-width="50"
          v-bind="column"
        >
          <template #default="{ row }" v-if="column.uiType">
            <template v-if="column.uiType === 'input'">
              <el-input-number
                v-model="row[column.prop]"
                :controls="false"
                controls-position="both"
                :max="999999"
                :min="0"
                placeholder="请输入"
                :step="1"
              />
            </template>
            <template v-else-if="column.uiType === 'text'">
              <div>
                {{ handleGetSkuAttrVal(row, column) }}
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-form-item>
</template>

<script>
  // 产品规格sku
  import { cloneDeep } from 'lodash-es'
  import { defineComponent, computed, watchEffect } from 'vue'
  export default defineComponent({
    name: 'ProductWeightSku',
    props: {
      item: { type: Object, default: () => {} },
      formModel: { type: Object, default: () => {} },
      salePropInfo: { type: Object, default: () => {} }, // 产品规格属性
      isSpecsSku: { type: Boolean, default: false }, // 是否是按规格sku报价
      isEdit: { type: Boolean, default: false }, // 是否是编辑
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const appModel = computed({
        get: () => {
          return props.formModel || {}
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })

      const defaultColumns = [
        {
          label: '序号',
          width: 100,
          type: 'index',
          align: 'center',
        },
        {
          label: '长(cm)',
          width: 180,
          prop: 'length',
          align: 'center',
          uiType: 'input',
        },
        {
          label: '宽(cm)',
          width: 180,
          prop: 'width',
          align: 'center',
          uiType: 'input',
        },
        {
          label: '高(cm)',
          width: 180,
          prop: 'height',
          align: 'center',
          uiType: 'input',
        },
        {
          label: '体积(cm³)',
          width: 180,
          prop: 'volume',
          align: 'center',
          uiType: 'text',
        },
        {
          label: '重量(g)',
          width: 180,
          prop: 'weight',
          align: 'center',
          uiType: 'input',
        },
      ]
      const logisticsCategory = computed(() => {
        return [
          {
            label: '按规格设置',
            value: 'sku',
          },
          {
            label: '按商品设置',
            value: 'item',
          },
        ]
      })
      /**
       * 生成sku
       * @param {*} arrays
       * @param {*} index
       * @param {*} currentCombination
       * @param {*} result
       */
      const handleGenerateSku = (arrays, index, currentCombination, result) => {
        if (index === arrays.length) {
          // 当前组合完成
          const values = currentCombination.map((v) => v.value)
          result.push({
            sku_props: [...currentCombination],
            length: null,
            volume: null,
            width: null,
            height: null,
            weight: null,
            sku_status: 1,
            values,
            key: `@${values.map((v) => Math.abs(v)).join('|')}`,
          })
          return
        }
        const currentAttribute = arrays[index]

        for (const value of currentAttribute.values) {
          // console.log('value :>> allSKUCombinations', value)
          // 递归生成下一个属性的组合
          handleGenerateSku(
            arrays,
            index + 1,
            [
              ...currentCombination,
              {
                ...currentAttribute.attrs,
                text: value.text,
                value: value?.value,
                pictureUrl: value?.pictureUrl || value?.imgUrl || '',
              },
            ],
            result
          )
        }
      }

      /**
       * 获取sku属性值
       * @param {*} row
       * @param {*} item
       */
      const handleGetSkuAttrVal = (row, item) => {
        const { prop } = item
        if (prop === 'volume') {
          if (row?.length && row?.width && row?.height) {
            return (row?.length || 0) * (row?.width || 0) * (row?.height || 0)
          }
          return 0
        }
        return row?.sku_props?.find((v) => v.name === prop)?.text || ''
      }

      // 价格sku
      const handleGetPriceSkuTableData = (dataSource) => {
        const result = []
        const salePropAttributes = []
        dataSource.map((item) => {
          const { name, label, propertyId } = item
          if (props.formModel[name] && props.formModel[name].length) {
            salePropAttributes.push({
              attrs: {
                label,
                name,
                id: propertyId,
              },
              values: props.formModel[name] || [],
            })
          }
        })
        // console.log('salePropAttributes :>> ', salePropAttributes)
        handleGenerateSku(salePropAttributes, 0, [], result)
        return {
          skuInfo: result,
          showLogisticsCategory: 'item',
          offerInfo: [
            {
              length: null,
              width: null,
              height: null,
              weight: null,
              volume: null,
            },
          ],
        }
      }

      const tableColumns = computed(() => {
        const columns = cloneDeep(defaultColumns)
        let hasSalePropValue = false
        props.salePropInfo.dataSource.forEach((item, i) => {
          const { name, label } = item
          if (props.formModel[name] && props.formModel[name].length) {
            hasSalePropValue = true
            columns.splice(1 + i, 0, {
              label: label,
              width: 120,
              prop: name,
              align: 'center',
              uiType: 'text',
            })
          }
        })
        // if(props.formModel.){

        // }
        console.log('defaultColumns :>> ', columns)
        return hasSalePropValue ? columns : []
      })

      watchEffect(() => {
        const labelKey = props.item.labelKey
        console.log('watchEffect', labelKey)
        console.log('propsprops.formModel watchEffect', props.formModel)
        appModel.value[labelKey] = handleGetPriceSkuTableData(
          props.salePropInfo.dataSource
        )
      })

      return {
        appModel,
        tableColumns,
        defaultColumns,
        logisticsCategory,
        handleGetSkuAttrVal,
      }
    },
  })
</script>

<style scoped></style>
