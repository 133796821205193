<!--
 * @Author: WGL
 * @Date: 2023-06-15 13:42:15
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-15 17:50:07
 * @Description: 
-->
<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      :table-height="tableHeight"
      @header-action="handleHeaderAction"
      @table-action="handleTableAction"
    />
    <AlbbPublishProduct ref="refAlbbPublishProduct" :info="openData" />
    <PosterDesign ref="PosterDesignRef" @saveImg="handleSaveImg" />
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onBeforeMount,
    ref,
    toRefs,
    computed,
    nextTick,
  } from 'vue'
  import { t } from '@/utils/i18n'
  import {
    productChannelAddDialog,
    productImgDesign,
  } from '@/utils/dialogBoxConfig'
  import { commonSearchConfig } from '@/utils/headerSearchConfig'
  import { CURRENCY_UNIT } from '@/config/variable.config'
  import request from '@/utils/request'
  import { uploadBase64 } from '@/config/index'
  import { postRequest } from '@/api/api'
  const MODULE = 'product-channel' // 模块

  export default defineComponent({
    name: 'ProductChannelList',
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    emits: [],
    setup(props) {
      console.log('props :>> ProductChannelList', props)
      const tableRef = ref(null)
      const PosterDesignRef = ref(null)
      const refAlbbPublishProduct = ref(null)
      const isAliType = 3
      const state = reactive({
        openData: {},
        selectImgIndex: 0,
        imageType: 'single', // 单图
        formModel: {
          shop_id: '',
          category_id: '',
        },
        setModel: null,
      })

      const tableHeight = computed(() => {
        return props.params?.channelType ? 'auto' : '75vh'
      })
      // 表格数据
      const tableColumns = [
        // { type: 'selection', width: 50, fixed: 'left' },
        { label: 'ID', prop: 'id', width: 85, sortable: 'custome' },
        {
          hide: props.params?.channelType,
          label: '关联产品',
          prop: 'product_info.title',
          width: 240,
          class: 'cp cb tdu',
          publicDrawerConfig: {
            openName: 'productDetail',
            api: {
              valueKey: 'product_id',
            },
          },
        },
        {
          label: '渠道类型',
          prop: 'channel_info.channel_type_info.title',
          width: 130,
        },
        {
          label: '渠道',
          prop: 'channel_info.title',
          width: 130,
        },
        // {
        //   label: '关联店铺',
        //   prop: 'shop_channel.title',
        //   minWidth: 130,
        // },
        // {
        //   label: '店铺/账号名称',
        //   prop: 'shop_channel.title',
        //   minWidth: 130,
        // },
        {
          label: '链接地址',
          prop: 'url',
          isLink: true,
          editType: 'link',
          minWidth: 240,
        },
        {
          label: '售价',
          prop: 'price',
          class: 'cd b',
          width: 120,
          valFun: (row) => {
            return `${CURRENCY_UNIT[row.currency]}${row.price}`
          },
        },
        {
          label: '售出产品数',
          prop: 'number',
          width: 130,
        },
        // { label: '第三方商品ID', prop: 'third_product_id', width: 130 },
        { label: '备注', prop: 'admin_remark', width: 180 },
        { label: '创建人', prop: 'creator.name', width: 150 },
        { label: '创建时间', prop: 'create_time', width: 110 },
        {
          width: 150,
          label: '操作',
          isOperate: true,
          fixed: 'right',
          action: {
            edit: {
              type: 'editBox',
            },
            // 绑定阿里巴巴产品
            bindProduct: {
              condition: (row) => {
                return (
                  row?.channel_info?.third_shop_info?.type === 1 &&
                  !row.third_product_id
                )
              },
            },
            // 更新阿里巴巴产品
            updateAlbb: {
              type: 'req',
              tips: '您确定要对当前产品更新到阿里巴巴？',
              api: {
                update: `/albb/pushProduct`,
                params: {},
              },
              refreshTable: true,
              condition: (row) => {
                return (
                  row?.channel_info?.third_shop_info?.type === 1 &&
                  row.third_product_id
                )
              },
            },
            // 图片设计
            imgDesign: {
              type: 'editBox',
              editBoxName: 'editBoxImgDesign',
            },
            // list: ['edit', 'delete', 'bindProduct', 'updateAlbb', 'imgDesign'],
            list: ['edit', 'delete', 'imgDesign'],
          },
        },
      ]
      let tableConfig = reactive({
        searchBtnInline: !props?.params?.from,
        headerActions: {
          add: {
            type: 'editBox',
          },
          list: [props.params?.channelType ? 'add' : ''],
        },
        useField: false,
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props?.params?.searchKey || '']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableExportPathKey: 'http_path',
        tableDeleteParams: {},
        tableButtonNum: 3,
        showPagination: !props?.params?.from,
        tableSearch: props?.params?.from ? null : commonSearchConfig('channel'),
        editBox: productChannelAddDialog(
          MODULE,
          props.params?.channelType || '',
          props.info,
          props.params
        ),
        editBoxImgDesign: productImgDesign(MODULE, '', {
          PosterDesignRef,
          state,
        }),
      })

      const handleTableAction = (action, row, rowAction, data) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
        console.log('rowAction :>> ', rowAction)
        console.log('data :>> ', data)
        const { code } = action
        if (code === 'bindProduct') {
          state.openData = row || {}
          nextTick(() => {
            refAlbbPublishProduct.value?.handleShowDialog()
          })
        }
      }

      const handleHeaderAction = (action) => {
        console.log('action', action)

        // setTimeout(() => {
        //   store.commit('sys/setOpenPageDrawerName', 'productDetail')
        // }, 1000)
      }

      const handleSaveImg = (data) => {
        PosterDesignRef.value.closePosterDesign()
        postRequest(uploadBase64, { filedata: data }).then((res) => {
          const url = res.relative_path
          if (state.imageType === 'single') {
            state.setModel('image', url)
          } else {
            let urls = F.copy(state.openData.slider_image)
            urls.splice(state.selectImgIndex, 1, url)
            state.setModel('slider_image', urls)
          }
        })
      }

      onBeforeMount(async () => {})
      return {
        PosterDesignRef,
        ...toRefs(state),
        tableConfig,
        tableRef,
        MODULE,
        request,
        isAliType,
        tableHeight,
        refAlbbPublishProduct,
        handleHeaderAction,
        handleTableAction,
        t,
        handleSaveImg,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    :deep(.input-number) {
      .el-input__inner {
        padding-right: 0;
      }
    }
  }
</style>
