

/**
 * @description 导出主题配置，注意事项：此配置下的项修改后需清理浏览器缓存！！！
 */
const localDict = SS.get('localDict')
module.exports = {
  // 产品搜索配置
  productSearchConfig: [
    { label: '聚合搜索', value: 'search', },
    { label: '产品标题', value: 'title', },
    { label: '产品编码', value: 'code', },
    { label: 'OEM编码', value: 'oem_code', },
    { label: '英文名称', value: 'title_en', },
    { label: '标签', value: 'label_title', },
  ],
  // 含有标签的聚合搜索
  labelSearchConfig: [
    { label: '聚合搜索', value: 'search', },
    { label: '标签', value: 'label_title', },
  ],
  LANGUAGE_LIST: {
    zh_CN: '简体中文',
    zh_TW: '繁體中文',
    en_US: 'English',
    es_ES: 'Español',
    de_DE: 'Deutsch',
    tr_TR: 'Türkçe',
    ru_RU: 'Русский язык'
    // { title: '繁體中文', type: 'zh_TW' },
    // { title: 'Español', type: 'pt_PT' },
  },
  COLOR: {
    error: '#FFD1D1',
    success: '#AEF2D2',
    warning: '#FFE3B6',
    normal: '#98E4FF',
    failed: 'rgb(209 209 209)',
    notice: 'rgb(254 255 146)',
  },
  DATETIME_FORMAT: 'YY/MM/DD HH:mm',
  DATE_FORMAT: 'YY/MM/DD',
  DATE_FORMAT2: 'YYYY-MM-DD',
  DATE_FORMAT3: 'YYYY/MM/DD',
  TODO_STATUS: {
    0: '已创建',
    1: '进行中',
    2: '已完成',
  },

  INDEX_TYPE: {
    0: '折线图',
    1: '柱状图',
  },
  INDEX_TYPE_ECAHART: {
    0: 'line',
    1: 'bar',
  },
  INDEX_CYCLE: {
    0: '日',
    1: '周',
    2: '月',
  },

  // 订单状态
  ORDER_STATUS: {
    '-10': '已作废',
    0: '已过期',
    5: '编辑中',
    10: '待支付',
    15: '已付定金',
    20: '已支付',
    30: '已发货',
    50: '已签收',
    100: '已完成',
  },
  ORDER_STATUS_COLOR: {
    0: 'info', // "已过期",
    1: 'danger', // "已退款",
    10: 'danger', // "待支付",
    20: 'success', // "已支付",
    30: '', // "备货中",
    100: 'success', //  "已完成"
  },
  // 订单子类型
  ORDER_SUBTYPR: {
    10001: '报价单-出口',
    10002: '报价单-内销',
    10003: '报价单-门市',
    10004: '报价单-1688',
    10005: '报价单-淘宝',
    10006: '报价单-云工厂',
    10007: '报价单-智能工厂',
  },
  NORMAL_STATUS: {
    0: '禁用',
    1: '启用',
  },
  NORMAL_STATUS_COLOR: {
    0: 'danger',
    1: 'success',
    2: 'warning',
    3: 'danger',
    4: 'info'
  },
  NORMAL_STATUS_STEP_COLOR: {
    0: 'danger',
    1: 'primary',
    5: 'warning',
    10: 'success',
    20: 'primary',
    30: 'info'
  },
  // 生产类型
  PRODUCTION_PLAN_TYPE_COLOR: {
    1: 'warning',
    2: 'success',
  },
  // 生产状态
  PRODUCTION_PLAN_STATUS_COLOR: {
    0: 'danger', // "待排产"
    10: '', //  "物料计划中",
    20: 'warning',// "待下发生产",
    30: 'success',// "已下发生产",
    100: 'success', // "已完成生产"
  },
  SHOW_STATUS: {
    0: '隐藏',
    1: '显示',
  },

  NORMAL_ONLINE_STATUS: {
    0: '离线',
    1: '在线',
  },

  /**
   * 变量配置
   */

  AUTHORIZE_PLATFORMS: {
    0: '未知',
    1: '微信',
    2: '抖音',
    3: '快手',
  },
  AUTHORIZE_PLATFORM: [
    {
      label: '微信',
      title: '微信',
      authorizeUrl: '/third/weixin/bind/url',
      img: '/assets/sys/wechat.png',
      color: '#2aae67',
      value: 1,
    },
    {
      label: '抖音',
      title: '抖音',
      authorizeUrl: '/third/douyin/bind/url',
      img: '/assets/sys/douyin.png',
      color: '#1c0b1b',
      value: 2,
    },
    {
      label: '快手',
      title: '快手',
      authorizeUrl: '/third/kuaishou/bind/url',
      img: '/assets/sys/kuaishou.png',
      color: '#ff4906',
      value: 3,
    },
  ],

  BILL_STATUS: {
    0: '待确定',
    1: '有效',
    '-1': '无效',
  },
  // 账单类型
  BILL_TYPE: {
    10: '推广微信公众号',
    11: '推广抖音账号',
    12: '推广抖音视频',
    13: '推广快手账号',
    14: '推广快手视频',
    20: '销售收入',
    21: '押金收入',
    22: '租赁收入',
    50: '购买支出',
    51: '押金退还',
  },

  GENDER: {
    0: '未知',
    1: '男',
    2: '女',
    3: '保密',
  },

  FEEDBACK_STATUS: {
    0: '待处理',
    10: '处理中',
    100: '已处理',
  },

  LIMIT_CYCLE_UNIT: {
    0: '日',
    1: '周',
    2: '月',
  },

  // 出入库类型
  STORAGE_RECORD_TYPE: {
    0: '出库',
    1: '入库',
  },
  STORAGE_RECORD_TYPE_COLOR: {
    0: 'primary', // 出库
    1: 'danger', // 入库
  },

  EXTRA_FIELD_TYPE: {
    text: '文本框',
    textarea: '多行文本框',
    inputNumber: '数字文本框',
    datePicker: '日期选择框',
    switch: '状态选择',
    radio: '单选框',
    checkbox: '多选框',
    select: '下拉单选择',
    selectMultiple: '下拉多选择',
    avatar: '单图片',
    avatarMultiple: '多图片',
  },
  // 扩展属性类型
  EXTEND_ATTR_TYPE: {
    text: {
      title: '文本框',
      type: 'input',
      option: false,
    },
    textarea: {
      title: '多行文本框',
      type: 'input',
      option: false,
    },
    inputNumber: {
      title: '数字文本框',
      type: 'inputNumber',
      option: false,
    },
    datePicker: {
      title: '日期选择',
      type: 'datePicker',
      option: false,
    },
    switch: {
      title: '状态选择',
      type: 'switch',
      option: false,
    },
    radio: {
      title: '单选框',
      type: 'radio',
      option: true,
    },
    checkbox: {
      title: '多选框',
      type: 'checkbox',
      option: true,
      multiple: true,
    },
    select: {
      title: '下拉单选择',
      type: 'select',
      multiple: false,
      option: true,
    },
    selectMultiple: {
      title: '下拉多选择',
      type: 'select',
      multiple: true,
      option: true,
    },
    avatar: {
      title: '单图片',
      type: 'uploadImg',
      multiple: false,
      option: false,
      isImg: true,
    },
    avatarMultiple: {
      title: '多图片',
      type: 'uploadImg',
      multiple: true,
      option: false,
      isImg: true,
    },
  },
  // 消息状态
  NOTICE_STATUS: {
    0: '未读',
    1: '已读',
  },
  // 审核状态
  AUDIT_STATUS_COLOR: {
    0: 'warning', // 未提审
    1: 'primary', // 审核中
    5: 'danger', // 不通过
    10: 'success', // 已通过
  },
  // 订单状态
  ORDER_AUDIT_STATUS_COLOR: {
    0: 'warning', // 未提审
    1: 'primary', // 审核中
    5: 'danger', // 不通过
    10: 'success', // 已通过
  },
  // 付款状态
  ORDER_PAY_STATUS_COLOR: {
    0: 'warning', //未付款
    1: 'primary', // 已付定金
    5: 'danger', // 部分付款
    10: 'success', //已付清
  },
  // 发货状态
  ORDER_DELIVERY_STATUS_COLOR: {
    0: 'warning', // 未发货
    5: 'primary', // 已发货
    10: 'success', // 已签收
  },
  // 订单产品详情状态
  SALE_ORDER_DETAIL_STATUS_COLOR: {
    0: "info", // 未计划
    5: "primary", // 部分计划
    10: "success", // 已计划
    30: "warning", // 部分发货
    50: "success" // 已发货
  },
  // 报价单状态
  QUOTE_STATUS_COLOR: {
    0: 'info', // 编辑中
    1: 'primary', // 审核中
    5: 'danger', // 审核不通过
    10: 'success', // 审核通过
    100: 'success', // 已下单
  },
  DELIVERY_PLAN_STEP_COLOR: {
    0: 'info', // 待处理
    10: 'warning', //待审核
    20: 'primary', // 备货中
    40: 'success', // 备货完成
    50: 'success', // 已出库
    100: 'success', // 已完成
  },
  PURCHASE_ORDER_STEPT: {
    0: 'info', // 编辑中
    1: 'warning', // 审核中
    20: 'danger', // 待付款
    30: 'primary', //备货中
    40: 'warning', // 待发货
    50: 'primary', // 已发货
    100: 'success', // 已完成
  },
  // 回款状态
  BILL_PLAN_STATUS_COLOR: {
    0: 'info', // 计划中,
    1: 'primary', // 审核中,
    2: 'warning', // 部分回款
    5: 'success', // 全额回款
    10: 'warning', // 超额回款
  },
  PURCHASE_PLAN_STATUS_COLOR: {
    0: 'warning', // 未采购
    5: 'primary', // 采购中
    10: 'success', // 已采购
    '-1': 'info', // 已作废
  },
  REPOSITORY_COMMON_STATUS_COLOR: {
    0: 'warning', // 待处理
    10: 'danger', // 待审核
    100: 'success', // 已完成
    '-1': 'info', // 已作废
  },
  // 出入库状态
  REPOSITORY_RECEIPT_STATUS_COLOR: {
    0: 'info', // 待处理
    10: 'warning', //待审核
    100: 'success', //已完成
  },
  // 商机类型
  BUSINESS_TYPE: {
    0: '私有',
    1: '公海',
  },
  BUSINESS_OPPORTUNITY_STAGE_COLOR: {
    0: 'info', //  "验证客户",
    5: '', // "需求分析",
    10: 'warning', //  "方案报价",
    15: 'danger', // "谈判审核"
  },
  END_STAGE_COLOR: {
    21: 'success', //  "成功",
    23: 'danger', // "失败"
  },
  // 任务类型
  TASK_STATUS_COLOR: {
    0: 'info', // 取消
    1: 'primary', // 进行
    2: 'warning', // 延期
    10: 'success', // 完成
  },
  // 优先级
  PRIORITY_TYPE: {
    0: '无',
    1: '低',
    2: '中',
    3: '高',
  },
  // 优先级
  PRIORITY_COLOR: {
    0: 'info',
    1: 'success',
    2: 'warning',
    3: 'danger',
  },
  colorArr: [
    '#F2637B',
    '#1890FF',
    '#36CBCB',
    '#4ECB73',
    '#FBD437',
    '#2362fb',
    '#975FE5',
  ],
  // 员工状态
  STAFF_STATUS: {
    0: '离职',
    1: '在职',
    2: '禁用',
  },
  STAFF_STATUS_COLOR: {
    0: 'danger', // 离职,
    1: 'success', // 在职
    2: 'warning', // 禁用
  },
  // 价格策略类型
  PRICE_POLICY_TYPE: {
    1: '销售',
    2: '采购',
  },
  // 价格策略类型
  PRICE_POLICY_MODE: {
    1: '按数量区间设置',
    2: '按客户/供应商设置',
  },
  // 报价状态
  ACCEPT_STATUS: {
    0: '待接受',
    1: '已接受',
    2: '不接受',
  },
  ACCEPT_STATUS_COLOR: {
    0: 'warning', // 待接受
    1: 'success', // 已接受
    2: 'danger', // 不接受
  },
  // 货源
  PRODUCT_SOURCE_COLOR: {
    0: 'danger', // 无货源
    1: 'success', // 自产货源
    2: 'warning', // 外部货源
    3: 'primary', // 组合货源
  },
  ...localDict,

  // 开票信息配置
  invoiceInfoConfig: [
    { title: '公司', key: 'company' },
    { title: '税号', key: 'tax_no' },
    { title: '币种', key: 'currency' },
    { title: '开户银行', key: 'bank' },
    { title: '银行账户', key: 'bank_account_no' },
    { title: '单位地址', key: 'address' },
    { title: '电话', key: 'telephone' },
    { title: '邮箱', key: 'email' },
  ],
  BANNER_TYPE: {
    1: '首页',
    // 2: '产品',
    // 3: '关于我们',
    // 4: '联系我们',
    // 5: '产品详情',
    // 6: '关于我们详情',
    // 7: '联系我们详情',
    // 8: '产品详情',
  },
  // 商品上下架状态
  SHOP_PRODUCT_STATUS: {
    0: '下架',
    1: '上架',
  },

  // 公司经营状态
  COMPANY_STATUS: {
    ACTIVE: 'Active',
    BANKRUPTCY: 'Bankrupcty',
    DISSOLVED: 'Dissolved',
    IN_LIQUIDATION: 'In liquidation',
    INACTIVE: 'Inactive',
    UNKNOWN: 'Status Unknown',
  },
  // 营收范围
  REVENUE_RANGE: {
    1: '0-1000(千美元)',
    2: '1000-5000(千美元)',
    3: '5000-10000(千美元)',
    4: '10000-50000(千美元)',
    5: '50000-*(千美元)',
    6: 'others',
  },
  // 员工人数范围
  EMPLOYEE_RANGE: {
    1: '0-5',
    2: '6-10',
    3: '11-50',
    4: '51-100',
    5: '101-500',
    6: '501-1000',
    7: '10001-*',
    8: 'others',
  },
  // 常规颜色
  COMMON_COLOR: {
    0: 'danger',
    1: 'success',
  },
  // 提示颜色
  TIPS_COLOR: {
    0: 'primary',
    1: 'danger',
  },
  // 是否含税
  CONTAIN_TAX: {
    0: '不含税',
    1: '含税',
  },
  // pm
  INCREASE_DECREASE: {
    0: '减少',
    1: '增加'
  },
  // 运输方式
  SHIP_MODE: {
    'exw': 'EXW',
    'fob': "FOB"
  },
  // 权限模式
  AUTH_MODE: {
    0: '宽松模式',
    1: '严格模式',
  },
  // 客类型
  FEEDBACK_TYPE: {
    product: '产品',
    'sale-order': '订单',
    supplier: '供应商',
  },
  // 字段排序
  FILED_ORDER: {
    desc: "降序",
    asc: '升序'
  },
  // 快递支付方式
  EXPRESS_PAY_TYPE: {
    MONTHLY: '月结',
    SHIPPER: '寄付',
    CONSIGNEE: '到付',
  },
  // 快递日期
  EXPRESS_DATA: {
    '今天': '今天',
    '明天': '明天',
    '后天': '后天',
  },
  YES_NO: {
    0: '否',
    1: '是',
  },
  // 产品货源
  PRODUCT_NATURE_COLOR: {
    0: 'success', //  "成品",
    1: 'warning', // "半成品",
    2: 'primary', // "原材料"
  },
  // 家具采购订单状态
  MHL_PURCHASE_ORDER_DETAIL_STATUS_COLOR: {
    0: 'warning', // "未付款"
    5: 'success', // "已付款"
    10: 'primary', //  "发往国内仓",
    15: 'success', // "到达国内仓",
    20: 'primary', // "发往香港仓",
    25: 'success', // "到达香港仓"
  },
  // 家具在仓状态
  furnitureRepositoryStatus: {
    10: '国内仓', // "待入国内库",
    15: '国内仓', // "在国内库",
    20: '国内仓', // "待发香港仓",
    30: '荔枝角', // "待入荔枝角",
    35: '荔枝角', // "在荔枝角",
    37: '荔枝角', // "取消派件",
    39: '荔枝角', // "回仓",
    40: '荔枝角', // "待发客户",
    45: '荔枝角', // "发往客户",
    50: '荔枝角', // "已送达",
    60: '深水涉', // "待入深水埗",
    65: '深水涉', // "在深水埗",
    67: '深水涉', // "取消派件",
    69: '深水涉', // "回仓",
    70: '深水涉', // "待发客户",
    75: '深水涉', // "发往客户",
    80: '深水涉', // "已送达",
  },
  furnitureStock: {
    10: '国内仓', // 待入国内库
    30: '荔枝角', // 待入荔枝角
    60: '深水涉', // 待入深水埗
  },
  // 家具在仓状态颜色
  furnitureRepositoryStatusColor: {
    10: 'warning', // "待入国内库",
    15: 'warning', // "在国内库",
    20: 'warning', // "待发香港仓",
    30: 'primary', // "待入荔枝角",
    35: 'primary', // "在荔枝角",
    37: 'primary', // "取消派件",
    39: 'primary', // "回仓",
    40: 'primary', // "待发客户",
    45: 'primary', // "发往客户",
    50: 'primary', // "已送达",
    60: 'success', // "待入深水埗",
    65: 'success', // "在深水埗",
    67: 'success', // "取消派件",
    69: 'success', // "回仓",
    70: 'success', // "待发客户",
    75: 'success', // "发往客户",
    80: 'success', // "已送达",
  },
  // 家具运输状态
  furnitureTransportStatus: {
    10: '待入库', // "待入国内库",
    15: '在仓', // "在国内库",
    20: '上车', // "待发香港仓",
    30: '待入库', // "待入荔枝角",
    35: '在仓', // "在荔枝角",
    37: '取消派件', // "取消派件",
    40: '上车', // "待发客户",
    45: '发往客户', // "发往客户",
    50: '已送达', // "已送达",
    60: '待入库', // "待入深水埗",
    65: '在仓', // "在深水埗",
    67: '取消派件', // "取消派件",
    70: '上车', // "待发客户",
    75: '发往客户', // "发往客户",
    80: '已送达', // "已送达",
  },
  // 家具运输状态颜色
  furnitureTransportStatusColor: {
    10: 'warning', // "待入国内库",
    15: 'success', // "在国内库",
    20: 'danger', // "待发香港仓",
    30: 'warning', // "待入荔枝角",
    35: 'success', // "在荔枝角",
    37: 'info', // "取消派件",
    39: 'warning', // "回仓",
    40: 'danger', // "待发客户",
    45: 'success', // "发往客户",
    50: 'success', // "已送达",
    60: 'warning', // "待入深水埗",
    65: 'success', // "在深水埗",
    67: 'info', // "取消派件",
    69: 'danger', // "回仓",
    70: 'danger', // "待发客户",
    75: 'success', // "发往客户",
    80: 'success', // "已送达",
  },

  MHL_HONGKONG_DISTRICT_COLOR: {
    "元天屯": "warning",
    "九龍": "danger",
    "新界": "success",
    "港島": "",
    "離島/偏遠": "warning",
    "九東+港東": "danger"
  },
  // 账期账单类型
  BILL_SHEET_TYPE: {
    customer: "客户账单",
    supplier: "供应商账单",
  },
  // 产品授权类型
  PRODUCT_GRANT_TYPE: {
    1: '全部产品',
    2: '产品分类',
    // 3: '产品车型'
  },
  // 滚动条加粗公司
  SCROLLBAR_BLOD_COMPANY: [
    'hg7ca286ab8f432b4c', // 乐穗
    'hg861ba25023ca07d8', // 嘉力德
    'hg8fa286ab8f434d8f', // 鲸苇科技
    'hg8fa286ab8f434d8f', // 测试环境
  ],
  // 滚动条加粗电话用户
  SCROLLBAR_BLOD_PHONE: [
    '13806102430', // 姚波
  ],
  // 磨具状态
  EQUIPMENT_GROUP_STATUS_COLOR: {
    0: 'info', // "探讨",
    1: 'primary', // "计划",
    2: 'warning', // "开发",
    3: 'danger', // "测试",
    4: 'success', // "投产"
  },
  // 磨具样品状态
  EQUIPMENT_GROUP_SAMPLE_STATUS_COLOR: {
    0: 'warning', // "未开始",
    1: 'primary', // "调样中",
    2: 'success', // "已到样"
  },
  // 磨具优先级
  EQUIPMENT_GROUP_PRIORITY_COLOR: {
    0: 'info', // "不重要",
    1: 'primary', // "普通",
    2: 'danger', // "重点"
  },
  // 打印标签类型
  PRINT_LABEL_TYPE: {
    'multiple': '按产品数量',
    'single': '按产品种类',
  },
}
